<template>
  <el-cascader
    ref="Cascader"
    v-model="list"
    class="cityList"
    :options="options"
    :props="{ label: 'areaDesc', value: 'areaCode', children: 'childArea' }"
    clearable
    filterable
    :show-all-levels="levels"
    :disabled="disabled"
    :placeholder="placeholder"
    :size="size"
    @visible-change="visibleChange"
    @change="handelChange"
  />
</template>

<script>
import request from '@/utils/request'
export default {
  name: 'CityList',
  props: {
    value: {
      type: [Array, String],
      default: () => ([])
    },
    view: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: [Boolean],
      default: false
    },
    placeholder: {
      type: [String],
      default: '请选择'
    },
    size: {
      type: [String],
      default: 'middle'
    },
    // 参数拼接方案
    format: {
      type: [String, Array],
      default: null
    },
    // 只传省市值为2
    maxLen: {
      type: Number,
      default: 3
    },
    loction: {
      type: [Boolean],
      default: false
    },
    levels: {
      type: [Boolean],
      default: true
    }
  },
  data() {
    return {
      list: [],
      options: [
        // {
        //   value: "zhinan",
        //   label: "指南",
        //   children: [{}],
        // },
      ],
      provinceData: {}
    }
  },
  computed: {
    // area_list() {
    //   return this.$store.state.settings.area_list
    // }
  },
  watch: {
    value(newValue) {
      if (newValue && this.format) {
        this.list = this.value.split(this.format)
      } else {
        this.list = this.value
      }
    }
  },
  mounted() {
    // if (this.area_list.length) {
    //   this.options = this.area_list
    // } else {
      this.getCity()
    // }
  },
  methods: {
    getCity() {
      request({
        url: '/sys/get/area/all',
        method: 'get'
      }).then((res) => {
        const childArea = res?.data?.childArea ?? []
        const deepFilter = (list, index) => {
          for (let i = 0; i < list.length; i++) {
            const item = list[i]
            const len = index + 1
            if ('childArea' in item && item.childArea.length !== 0 && len <= this.maxLen) {
              const chiled = item.childArea
              deepFilter(chiled, len)
            } else {
              delete item.childArea
            }
          }
        }
        // 递归数据清洗
        deepFilter(childArea, 1)
        this.options = res.data.childArea
        // if (this.options.length && !this.area_list.length) {
        //   this.$store.commit('settings/SET_AREA_LIST', this.options)
        // }
      })
    },
    visibleChange(bool) {
      if (!bool) {
        if (this.list.length === 1) {
          this.list = []
        }
      }
    },
    handelChange(res) {
      this.$nextTick(() => {
        // 获取节点
        const node = this.$refs['Cascader'].getCheckedNodes()
        const list = res || []
        // 获取中文地址
        if (this.loction) {
          const loction = node || []
          if (loction.length !== 0 && loction[0].pathLabels) {
            this.$emit('input', loction[0].pathLabels.join(','))
          }
          return
        }
        if (node.length) {
          this.$emit('change', node[0])
        }
        if (this.format) {
          this.$emit('input', list.join(this.format))
        } else {
          this.$emit('input', list)
        }
      })
    },
    clearCheckedNodes() {
      console.log('清空执行')
    }
  }
}
</script>
<style scoped lang="scss">
.cityList {
  width: 100%;
  ::v-deep .el-input {
    .el-input__inner {
      padding-left: 30px;
    }
    &::before {
      content: "\e607";
      font-family: "iconfont";
      background-size: 14px;
      color: #c6cad1;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
    }
  }
}
</style>
