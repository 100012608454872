<template>
  <div class="comp-bottom">
    <!-- <div class="comp">
    <div class="info_body">
    <ul>
      <li class="font_style">产品</li>
      <li class="message">产品和服务</li>
    </ul>
    <ul>
      <li class="font_style">行业</li>
      <li class="message">行业解决方案</li>
    </ul>
    <ul>
      <li class="font_style">公司</li>
      <li class="message">公司简介</li>
    </ul>
    <ul>
      <li class="font_style">其他</li>
      <li class="message">客服热线：0571-57294</li>
      <li class="message">服务时间：工作日 9:00-18:30</li>
    </ul>
  </div>
  <div class="copyright">
    <div style="margin-bottom: 10px;">
      <span>帮助</span>
      <span>隐私</span>
      <span>条款</span>
    </div> -->

    <!-- </div>
                             
  </div> -->
    <div style="display: flex;align-items: center;line-height: 20px;font-size: 14px;">
      <span style="margin-right:20px;">Copyright&nbsp;©&nbsp;2024 杭州因斯科技有限公司技术支持</span>
      <span style="margin-right:20px;cursor: pointer;" @click="goJump"> 浙ICP备2024136511号</span>
      <span style="display: inline-flex;align-items: center;">
        <img src="@/assets/images/gongan.png" alt="" width="16" height="16" style="margin-right: 5px;">
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33010502012145" rel="noreferrer"
          target="_blank">浙公网安备33010502012145</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomInfo",
  methods: {
    goJump() {
      window.open('https://beian.miit.gov.cn', '_blank')
    },
  }
}
</script>

<style lang="scss">
.comp-bottom {
  // background: #f9fbff;
  width: 100%;
  // height: 100px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .comp {
    width: 1360px;
    margin: 0 auto;
    padding: 30px 30px 30px 50px;
    .info_body {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      ul {
        list-style-type: none;
        width: 33%;
        color: #606270;
      }
    }
    .font_style {
      font-weight: bold;
      font-size: 19px;
      margin: 50px 0 20px 0;
    }
    .message {
      font-size: 15px;
      margin: 0 0 10px 0;
      color: #909399;
    }
    .copyright {
      color: #8a8b8d;
      font-size: 15px;
      text-align: center;
      margin: 50px 0 0 0;
      span {
        margin: 30px;
      }
    }
  }
}
</style>
