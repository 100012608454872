<template>
  <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body @opened="modalOpened" @close="closeDialog">
    <el-row>
      <el-col :xs="24" :md="12" :style="{ height: '350px' }">
        <vue-cropper
          v-if="visible"
          ref="cropper"
          :img="options.img"
          :info="true"
          :auto-crop="options.autoCrop"
          :auto-crop-width="options.autoCropWidth"
          :auto-crop-height="options.autoCropHeight"
          :fixed-box="options.fixedBox"
          :output-type="options.outputType"
          @realTime="realTime"
        />
      </el-col>
      <el-col :xs="24" :md="12" :style="{ height: '350px' }">
        <div v-if="type === 'circle'" class="avatar-upload-preview">
          <img :src="previews.url" :style="previews.img" />
        </div>
        <div v-else class="rectangle">
          <img :src="previews.url" :style="previews.img" />
        </div>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :lg="2" :sm="3" :xs="3">
        <el-upload action="#" :http-request="requestUpload" :show-file-list="false" :before-upload="beforeUpload">
          <el-button size="small">
            选择
            <i class="el-icon-upload el-icon--right"></i>
          </el-button>
        </el-upload>
      </el-col>
      <el-col :lg="{ span: 1, offset: 2 }" :sm="2" :xs="2">
        <el-button icon="el-icon-plus" size="small" @click="changeScale(1)" />
      </el-col>
      <el-col :lg="{ span: 1, offset: 1 }" :sm="2" :xs="2">
        <el-button icon="el-icon-minus" size="small" @click="changeScale(-1)" />
      </el-col>
      <el-col :lg="{ span: 1, offset: 1 }" :sm="2" :xs="2">
        <el-button icon="el-icon-refresh-left" size="small" @click="rotateLeft()" />
      </el-col>
      <el-col :lg="{ span: 1, offset: 1 }" :sm="2" :xs="2">
        <el-button icon="el-icon-refresh-right" size="small" @click="rotateRight()" />
      </el-col>
      <el-col :lg="{ span: 6, offset: 6 }" :sm="6" :xs="6">
        <el-button :loading="loading" type="primary" size="small" @click="uploadImg()">提 交</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import store from '@/store'
import { VueCropper } from 'vue-cropper'
import { debounce } from '@/utils'
import upload from '@/utils/upload'

export default {
  components: { VueCropper },
  props: {
    url: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'circle'
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 是否显示cropper
      visible: false,
      loading: false,
      // 弹出层标题
      title: '设置头像',
      options: {
        img: '', // 裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: this.width, // 默认生成截图框宽度
        autoCropHeight: this.height, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        outputType: 'png', // 默认生成截图为PNG格式
        filename: 'avatar' // 文件名称
      },
      previews: {},
      resizeHandler: null
    }
  },
  methods: {
    // 编辑头像
    editCropper() {
      this.open = true
      this.loading = false
      if (this.url) {
        this.options.img = this.url
      }
    },
    // 打开弹出层结束时的回调
    modalOpened() {
      this.visible = true
      if (!this.resizeHandler) {
        this.resizeHandler = debounce(() => {
          this.refresh()
        }, 100)
      }
      window.addEventListener('resize', this.resizeHandler)
    },
    // 刷新组件
    refresh() {
      this.$refs.cropper.refresh()
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    // 上传预处理
    beforeUpload(file) {
      // eslint-disable-next-line eqeqeq
      if (file.type.indexOf('image/') == -1) {
        this.$modal.msgError('文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。')
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.options.img = reader.result
          this.options.filename = file.name
        }
      }
    },
    // 上传图片
    uploadImg() {
      if (!this.previews.url) {
        this.$modal.msgError('请选择图片')
        return
      }
      this.loading = true
      this.$refs.cropper.getCropBlob(data => {
        upload(new File([data], this.options.filename, { type: data.type }), {
          resourceType: 1,
          module: 'fs_manage'
        })
          .then(res => {
            this.open = false
            this.$emit('back', res)
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      })
    },
    // 实时预览
    realTime(data) {
      this.previews = data
    },
    // 关闭窗口
    closeDialog() {
      this.options.img = store.getters.avatar
      this.visible = false
      window.removeEventListener('resize', this.resizeHandler)
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 60px 40px !important;
}
.user-info-head {
  position: relative;
  display: inline-block;
  height: 120px;
}
.user-info-head:hover:after {
  content: '+';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #eee;
  background: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  line-height: 110px;
  border-radius: 50%;
}

.avatar-upload-preview {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
}
</style>
