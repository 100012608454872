<template>
  <div class="finance">
    <div class="order_title">
      {{ form.customerName || '某某公司' }}对账单
    </div>
    <div class="time"> 账单期间：{{ dateFunction(form.reconciliationDetailVo.reconciliationStartTime) }} 至
      {{ dateFunction(form.reconciliationDetailVo.reconciliationEndTime) }}</div>

    <table class="serve-table">
      <tr>
        <td class="label">需方名称：</td>
        <td colspan="3" class="tl" style="text-align:center">
          {{ form.customerName || '-' }}
        </td>
        <td class="label">对账周期：</td>
        <td>{{ form.reconciliationPeriod }}</td>
      </tr>
      <tr>
        <td class="label">送货地址：</td>
        <td colspan="3">
          {{ form.deliveryAddress || '-' }}
        </td>
        <td class="label">是否含税：</td>
        <td> {{ form.isTax || '-' }}</td>
      </tr>
      <tr>
        <td width="200px" class="label">需方采购：</td>
        <td width="200px">{{ form.checkCgUserName || '-' }}</td>
        <td width="200px" class="label">联系电话：</td>
        <td width="200px">{{ form.checkCgPhone || '-' }}</td>
        <td width="200px" class="label">供方销售：</td>
        <td>{{ form.saleUserName || '-' }}</td>
      </tr>
      <tr>
        <td class="label">需方财务：</td>
        <td>{{ form.checkCwUserName || '-' }}</td>
        <td class="label">联系电话：</td>
        <td>{{ form.checkCwPhone || '-' }}</td>
        <td class="label">供方财务：</td>
        <td>{{ form.supplierCheckCgUserName || '-' }}</td>
      </tr>

      <tr class="label">
        <td>
          <span class="seq">序号</span>
          <span class="market">日期</span>
        </td>
        <td>服务编号</td>
        <td>服务类型</td>
        <td>批次</td>
        <td>订单/合同号</td>
        <td class="paddingRingt6">
          <span class="w60">本期金额</span>
          <span class="w40">备注</span>
        </td>
      </tr>
      <tr>
        <td></td>
        <td colspan="4" class="tl">尚欠货款期初余额：</td>
        <td class="paddingRingt6">
          <span v-if="form.reconciliationDetailVo" class="w60">{{ voInfo.initialDebt | formatMoney }}</span>
          <span class="w40">加项</span>
        </td>
      </tr>
    </table>
    <div v-if="expandList.length" class="tbody">
      <table class="serve-table">
        <tr v-for="(item,index) in expandList" :key="index">
          <td width="226.5px">
            <span class="seq">{{ index+1 }}</span>
            <span class="market">{{ formattedDate(item.serviceTime) }}</span>
          </td>
          <td width="226.5px">{{ item.serviceNo }}</td>
          <td width="226.5px">{{ item.serviceType }}</td>
          <td width="226.5px">{{ item.batch }}</td>
          <td width="226.5px">{{ item.relatedOrderNo }}</td>
          <td>
            <span class="w60">{{ item.amountThisPeriod | formatMoney }}</span>
            <span class="w40">{{ item.remark }}</span>
          </td>
        </tr>
      </table>
      
    </div>
    <table class="serve-table">
      <tr v-if="!expandList.length">
        <td class="label" height="130" colspan="6">
          暂无数据
        </td>
      </tr>
      <tr>
        <td width="226.5px"></td>
        <td width="906px" colspan="4" class="tl">本期应收货款小计：</td>
        <td class="paddingRingt6">
          <span class="w60">{{ voInfo.currentReceivableSubtotal | formatMoney }}</span>
          <span class="w40">加项</span>
        </td>
      </tr>
      <tr class="label">
        <td>
          <span class="seq">序号</span>
          <span class="market">日期</span>
        </td>
        <td colspan="4">付款说明</td>
        <td class="paddingRingt6">
          <span class="w60">本期金额</span>
          <span class="w40">备注</span>
        </td>
      </tr>
    </table>
    <div v-if="expandList1.length" class="tbody">
      <table class="serve-table">
        <tr v-for="(item,index) in expandList1" :key="index">
          <td width="226.5px">
            <span class="seq">{{ index+1 }}</span>
            <span class="market">{{ formattedDate(item.paymentTime) }}</span>
          </td>
          <td width="906px" colspan="4">{{ item.paymentDesc }}</td>
          <td>
            <span class="w60">{{ item.paymentAmount | formatMoney }}</span>
            <span class="w40">{{ item.remark }}</span>
          </td>
        </tr>
      </table>
    </div>
    <table class="serve-table">
      <tr v-if="!expandList1.length">
        <td class="label" height="130" colspan="6">
          暂无数据
        </td>
      </tr>
      <tr>
        <td width="226.5px"></td>
        <td width="906px" colspan="4" class="tl">本期实际已收款小计：</td>
        <td class="paddingRingt6">
          <span class="w60">{{ voInfo.currentActualReceivedSubtotal | formatMoney }}</span>
          <span class="w40">减项</span>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>尚欠货款期末余额：</td>
        <td colspan="3">单位：人民币 元，大写：{{ voInfo.finalDebtChinese || '-' }}</td>
        <td class="paddingRingt6">
          <span class="w60">{{ voInfo.finalDebt | formatMoney }}</span>
          <span class="w40">合计</span>
        </td>
      </tr>
    </table>
 
  </div>
</template>

<script>
import {
  getList
} from '@/api/finance'
import moment from 'moment'

export default {
  name: "finance",

  data() {
    return {
      title: '客户详情',
      form: {},
      voInfo: {},
      serviceTypes: ['售前交流', '样机调试', '交付调试', '售后服务', '退货检测'],
      moServiceAccessorieList: [],
      starRating: 0,
      expandList: [],
      expandList1: [],
      role: '',
      form2: {
        reconciliationMode: false,
        billDate: '',
        reconciliationPeriod: ''
      },
      timeData: [
        { value: 1, label: '1个月' },
        { value: 2, label: '2个月' },
        { value: 3, label: '3个月' },
        { value: 4, label: '4个月' },
        { value: 5, label: '5个月' },
        { value: 6, label: '6个月' },
        { value: 9, label: '9个月' },
        { value: 12, label: '12个月' }
      ],
      customerNo: ''
    }
  },
  computed: {

  },
  mounted() {
    this.getdata()
  },
  methods: {
    getdata() {
      let user = this.$Cookie.get('user_info')
      const tempUserInfo = JSON.parse(user)
      const data = {
        customerId: tempUserInfo.customerId
      }
      getList(data).then(r => {
        this.form = r.data
        this.voInfo = this.form.reconciliationDetailVo
        this.expandList = this.voInfo.reconciliationDetails
        this.expandList1 = this.voInfo.paymentInfo
      })
    },
    dateFunction(time) {
      var zoneDate = new Date(time).toJSON();
      var date = new Date(+new Date(zoneDate) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
      return date;
    },
    formattedDate(time) {
      // 使用 Moment.js 格式化日期
      return time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : '--'
    }

  },
}
</script>

<style lang="scss" scoped>
.finance {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0 60px 0;
  transform: translateX(3px);

  .order_title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0px;
  }

  .time {
    font-size: 17px;
    color: #333;
  }

  .content {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ededed;

    .top-table {
      width: 100%;
      background-color: #fff;

      table {
        td {
          width: 17%;
        }
      }
    }

    .con-table {
      width: 100%;
      background-color: #fff;
    }

    .bto-table {
      width: 100%;
      background-color: #fff;
    }
  }
}

.serve-table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  table-layout: fixed;
}
.serve-table .is-required,
.sub-title .is-required {
  position: relative;
  line-height: 30px;
  &::before {
    content: "*";
    color: #ff4949;
    margin-right: 4px;
    position: absolute;
    margin-left: -6px;
    margin-top: 3px;
  }
}
.serve-table td {
  border: 1px solid #ccc;
  line-height: 20px;
  padding: 1px;
  position: relative;
  min-height: 30px;
  .el-input,
  .inline-input,
  .el-select {
    width: 100%;
  }
  .el-input__inner,
  .el-textarea__inner {
    border-radius: 0;
  }
  .el-select {
    .el-input__inner {
      text-align: center;
    }
  }
}
.serve-table .text {
  margin: 0 auto;
  text-align: right;
  width: 60px;
}
.serve-table .tl {
  text-align: left;
}
.serve-table .tc {
  text-align: center;
}
.serve-table .h150 td {
  height: 150px;
}
.serve-table .seq {
  width: 40px;
  text-align: center;
  color: #7f7f7f;
  border-right: 1px solid #eee;
  float: left;
  position: relative;
  margin: -3px 0 -3px 0;
  padding: 3px 0;
}
.serve-table .market {
  display: inline-block;
  width: calc(100% - 40px);
}
.serve-table .w60 {
  width: 60%;
  text-align: center;
  color: #7f7f7f;
  border-right: 1px solid #eee;
  float: left;
  position: relative;
  margin: -3px 0 -3px -10px;
  padding: 3px 0;
}
.serve-table .w40 {
  display: inline-block;
  width: 40%;
}
.serve-table .tip {
  padding-left: 15px;
}
.serve-table .time {
  padding-left: 50px;
  margin-top: 20px;
}
.serve-table .time span {
  padding-left: 40px;
}

.paddingRingt6{
  padding-right: 0;
}
.tbody {
  display: block;
  // max-height: 250px;
  // overflow-y: scroll;
  // margin: -1px 0 -1px;
}
</style>
