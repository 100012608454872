<template>
  <div class="error">
    <img :src="defaultImg" alt="" width="500" height="500">
    {{ message }}
  </div>
</template>

<script>
import {
  getInfo,
} from '@/api/mallindex'
import defaultImg from "@/assets/images/wuxiao.svg"
export default {
  name: "error",
  components: {},
  data() {
    return {
      defaultImg:defaultImg,
      message: ''
    }
  },
  created() {
    var that = this;
    if (that.$route?.query?.type === '2') {
      that.message = '无效链接'
    } else {
      that.message = '当前商城暂时无法访问'
    }
    that.getList()
  },
  methods: {
    getList() {
      const lastPart = this.$Cookie.get('distributor')
      if (lastPart) {
        getInfo(lastPart).then(res => {
          if (res.data?.systemConfig?.mallSwitch === 0) {
            this.$router.push("/error")
          } else {
            this.$router.push("/website/shophome")
          }
        })
      }
    }
  },
  computed: {},
}
</script>
<style  lang="scss" scoped>
.error {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 30px;
  display: flex;
  color: #cccccc;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>