<template>
  <div class="detail-ordermanage">
    <div class="body-table">
      <div style="display: flex;margin-bottom: 15px">
        <div style="width: 2px; height: 20px; background-color: rgba(64, 158, 255); margin-right: 8px;"></div>
        订单内容
      </div>
      <div class="table-wrap" style="width: 100%">
        <!-- <div class="right"> -->
        <div class="y-table" style="width: 100%">
          <el-table
            ref="expandTable"
            :data="[orderRow]"
            max-height="275"
            :cell-style="{ width: '100%', padding: '0 5px 0 5px' }"
            tooltip-effect="dark"
            lazy
          >
            <el-table-column label="序号" align="center" type="index" width="50" />
            <el-table-column width="150" label="订单号" align="left" prop="orderNo" />
            <el-table-column label="订单类型" align="center" prop="orderType" />
            <el-table-column label="客户名称" align="left" width="220px" prop="customerName" />
            <!-- <el-table-column label="提交人" align="center" prop="submitterName" /> -->
            <el-table-column label="负责销售" align="center" prop="saleUserName" />
            <!-- <el-table-column label="发货模式" align="center" prop="executionMode" /> -->
            <el-table-column label="需货时间" align="center" prop="deliveryDateTime" />
            <el-table-column label="订单状态" align="center" prop="orderStatus" />
            <el-table-column label="处理人" align="center" prop="updateBy" />
            <el-table-column label="订单金额" align="center" prop="orderAmount">
              <template slot-scope="scope">
                {{ scope.row.orderAmount | formatMoney }}
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-loading="loading"
            :data="productList"
            :header-cell-style="{
              height: '25px',
              background: '#f2f2f2',
              padding: '0'
            }"
            :cell-style="{ width: '100%', padding: '0 5px 0 5px' }"
            :row-style="{ height: '25px' }"
            max-height="150"
          >
            <el-table-column label="序号" align="center" type="index" width="50" />
            <el-table-column label="产品型号" align="left" width="150px" prop="productMarque" />
            <el-table-column label="单位" align="center" prop="unit" />
            <el-table-column label="品牌" align="center" prop="brand" />
            <el-table-column label="品类" align="center" prop="productCategory" />
            <el-table-column label="采购单号" align="left" prop="purchaseOrderNo" min-width="130" />
            <el-table-column label="单价" align="center" prop="benchmarkPrice" />
            <el-table-column label="数量" align="center" prop="productNum" />
            <el-table-column label="总价" align="center" prop="productAmount" width="80">
              <template slot-scope="scope">
                {{ scope.row.productAmount | formatMoney }}
              </template>
            </el-table-column>
            <el-table-column label="执行进度" align="center" prop="executeStatus" width="100" />
          </el-table>
          <div class="bottom">
            <span class="total-name">总计</span>
            <span class="num">{{ total().totalNum |formatMoney }}</span>
            <span
              class="total"
              style="margin-right: 120px;display: inline-block;width: 80px;"
            >{{ total().totalPrice |formatMoney }}</span>
          </div>
        </div>

        <!-- </div> -->
      </div>
      <div v-loading="loading" class="super">
        <div style="display: flex">
          <div style="width: 2px; height: 20px; background-color: rgba(64, 158, 255); margin-right: 8px"></div>
          审批流程
        </div>
        <div class="super-content">
          <div v-for="(item, index) in flowItemDTOS" :key="index" class="node-item">
            <div v-if="item.levelNo !==null" class="super-tree" :class="{'active': item.flowItemStatus }">
              <div style="font-weight: bold;">{{ item.flowItemName }}</div>
              <div style="display: flex; margin-top: 10px; position: relative">
                <div style="display: flex">
                  <div
                    style="width: 32px; height: 32px; border-radius: 50%; background-color: #fff;display: flex;align-items: center;justify-content: center;"
                    :style="{ border: item.flowItemStatus ? 'none' : '1px solid rgba(64, 158, 255)','background-color':item.flowItemStatus? '#ffffff50' :'#fff', }"
                  >
                    <img
                      v-if="item && item.userLogo"
                      alt=""
                      width="30"
                      height="30"
                      :src="item && item.userLogo"
                      style="border-radius: 50%"
                    />
                    <img v-else :src="avatar" alt="" />
                  </div>
                  <div style="margin: 0px 10px;font-weight: bold">{{ item.flowItemUserName }}</div>
                </div>
                <div>
                  <div style="margin: 0 0 10px 0;font-weight: bold;">{{ item.flowItemRemark }}</div>
                  <div>{{ item.createTime }}</div>
                </div>
                <div style="margin-top: 26px; position: absolute; right: 0px;">审批耗时：{{ item.operTime }} 分钟</div>
              </div>
            </div>
            <div v-else>
              <div v-if="(item.subFlowCg && item.subFlowCg.length>0) && (item.subFlowCw && item.subFlowCw.length>0)">
                <div class="super-branch-box">
                  <div class="super-branch" :class="{'actives':flowItemDTOS[index - 1].flowItemStatus}"></div>
                  <div class="left">
                    <div
                      v-for="(subFlowL, idx) in item.subFlowCg"
                      :key="idx"
                      class="super-branch-left"
                      :class="{'actives': subFlowL.flowItemStatus }"
                    >
                      <div style="font-weight: bold;">{{ subFlowL.flowItemName }}</div>
                      <div style="display: flex; position: relative; margin-top: 10px">
                        <div style="display: flex">
                          <div
                            style="width: 32px; height: 32px; border-radius: 50%; background-color: #fff;display: flex;align-items: center;justify-content: center;"
                            :style="{ border: item.flowItemStatus ? 'none' : '1px solid rgba(64, 158, 255)','background-color':item.flowItemStatus? '#ffffff50' :'#fff', }"
                          >
                            <img
                              v-if="subFlowL && subFlowL.userLogo"
                              alt=""
                              width="30"
                              height="30"
                              style="border-radius: 50%"
                              :src="subFlowL && subFlowL.userLogo"
                            />
                            <img v-else :src="avatar" alt="" />
                          </div>
                          <div style="margin: 0px 10px;font-weight: bold;">{{ subFlowL.flowItemUserName }}</div>
                        </div>
                        <div>
                          <div style="margin-bottom: 10px;font-weight: bold;">{{ subFlowL.flowItemRemark }}</div>
                          <div>{{ subFlowL.createTime }}</div>
                        </div>
                        <div style="margin-top:26px; position: absolute; right: 0px;">审批耗时：{{ subFlowL.operTime }} 分钟
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <div
                      v-for="(subFlowR, idx) in item.subFlowCw"
                      :key="idx"
                      class="super-branch-right"
                      :class="{'actives': subFlowR.flowItemStatus }"
                    >
                      <div style="font-weight: bold;">{{ subFlowR.flowItemName }}</div>
                      <div style="display: flex; position: relative; margin-top: 10px">
                        <div style="display: flex">
                          <div
                            style="width: 32px; height: 32px; border-radius: 50%; background-color: #fff;display: flex;align-items: center;justify-content: center;"
                            :style="{ border: item.flowItemStatus ? 'none' : '1px solid rgba(64, 158, 255)','background-color':item.flowItemStatus? '#ffffff50' :'#fff', }"
                          >
                            <img
                              v-if="subFlowR && subFlowR.userLogo"
                              alt=""
                              width="30"
                              height="30"
                              style="border-radius: 50%"
                              :src="subFlowR && subFlowR.userLogo"
                            />
                            <img v-else :src="avatar" alt="" />
                          </div>
                          <div style="margin: 0px 10px;font-weight: bold;">{{ subFlowR.flowItemUserName }}</div>
                        </div>
                        <div>
                          <div style="margin-bottom: 10px;font-weight: bold;">{{ subFlowR.flowItemRemark }}</div>
                          <div>{{ subFlowR.createTime }}</div>
                        </div>
                        <div style="margin-top: 26px; position: absolute; right: 0px;">审批耗时：{{ subFlowR.operTime }} 分钟
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="super-line"
                  :class="{'active': item.subFlowCw[0].flowItemStatus && item.subFlowCg[1].flowItemStatus}"
                >
                  <div class="tubiao"></div>
                </div>
              </div>
              <div v-if="((item.subFlowCw === null) || (item.subFlowCw && item.subFlowCw.length===0) )&& (item.subFlowCg && item.subFlowCg.length>0)">
                <div v-for="(ite, idx) in item.subFlowCg" :key="idx" class="node-item">
                  <div class="super-tree" :class="{'active': ite.flowItemStatus }">
                    <div style="font-weight: bold;">{{ ite.flowItemName }}</div>
                    <div style="display: flex; margin-top: 10px; position: relative">
                      <div style="display: flex">
                        <div
                          style="width: 32px; height: 32px; border-radius: 50%; background-color: #fff;display: flex;align-items: center;justify-content: center;"
                          :style="{ border: ite.flowItemStatus ? 'none' : '1px solid rgba(64, 158, 255)','background-color':item.flowItemStatus? '#ffffff50' :'#fff', }"
                        >
                          <img
                            v-if="ite && ite.userLogo"
                            alt=""
                            width="30"
                            height="30"
                            :src="ite && ite.userLogo"
                            style="border-radius: 50%"
                          />
                          <img v-else :src="avatar" alt="" />
                        </div>
                        <div style="margin: 0px 10px;font-weight: bold">{{ ite.flowItemUserName }}</div>
                      </div>
                      <div>
                        <div style="margin: 0 0 10px 0;font-weight: bold;">{{ ite.flowItemRemark }}</div>
                        <div>{{ ite.createTime }}</div>
                      </div>
                      <div style="margin-top: 26px; position: absolute; right: 0px;">审批耗时：{{ ite.operTime }} 分钟</div>
                    </div>
                  </div>

                  <div
                    style="display: block;"
                    class="super-line"
                    :class="{'active': ite.flowItemStatus}"
                  >
                    <div class="tubiao"></div>
                  </div>
                </div>

              </div>
              <div v-if="((item.subFlowCg === null)||(item.subFlowCg && item.subFlowCg.length===0)) && (item.subFlowCw && item.subFlowCw.length>0)">
                <div v-for="(ite, idx) in item.subFlowCw" :key="idx" class="node-item">
                  <div class="super-tree" :class="{'active': ite.flowItemStatus }">
                    <div style="font-weight: bold;">{{ ite.flowItemName }}</div>
                    <div style="display: flex; margin-top: 10px; position: relative">
                      <div style="display: flex">
                        <div
                          style="width: 32px; height: 32px; border-radius: 50%; background-color: #fff;display: flex;align-items: center;justify-content: center;"
                          :style="{ border: ite.flowItemStatus ? 'none' : '1px solid rgba(64, 158, 255)','background-color':item.flowItemStatus? '#ffffff50' :'#fff', }"
                        >
                          <img
                            v-if="ite && ite.userLogo"
                            alt=""
                            width="30"
                            height="30"
                            :src="ite && ite.userLogo"
                            style="border-radius: 50%"
                          />
                          <img v-else :src="avatar" alt="" />
                        </div>
                        <div style="margin: 0px 10px;font-weight: bold">{{ ite.flowItemUserName }}</div>
                      </div>
                      <div>
                        <div style="margin: 0 0 10px 0;font-weight: bold;">{{ ite.flowItemRemark }}</div>
                        <div>{{ ite.createTime }}</div>
                      </div>
                      <div style="margin-top: 26px; position: absolute; right: 0px;">审批耗时：{{ ite.operTime }} 分钟</div>
                    </div>
                  </div>
                  <div
                    style="display: block;"
                    class="super-line"
                    :class="{'active': ite.flowItemStatus}"
                  >
                    <div class="tubiao"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="item.levelNo"
              class="super-line"
              :class="{'active': item.flowItemStatus}"
            >
              <!-- :class="{'active': item.flowItemStatus !== null ? item.flowItemStatus: item.subFlowCw[0].flowItemStatus && item.subFlowCg[1].flowItemStatus}" -->
              <div class="tubiao"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryOrderFLowDetail } from '@/api/orderManages'
import { getUserInfo } from '@/utils/auth'
import avatar from '@/assets/images/avatar.svg'
import { EXECUTE_STATUS } from '@/utils/const'
import * as _ from 'lodash'
import stylus from 'highlight.js/lib/languages/stylus'
export default {
  props: {
    orderRow: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      avatar: avatar,
      loading: false,
      delivery: true,
      form: {},
      dataList: [],
      searchName: '',
      flowItemDTOS: [],
      productList: [],
      commonParams: {
        distributorCompanyId: getUserInfo().distributorCompanyId || 0,
        distributorId: getUserInfo().distributorId
      }
    }
  },
  computed: {
    total() {
      return () => {
        let totalNum = 0
        let totalPrice = 0
        this.productList.map(item => {
          if (item.productNum) {
            totalNum = totalNum + item.productNum
          }
          totalPrice = totalPrice + item.productAmount
        })
        totalPrice = totalPrice.toFixed(2)
        return { totalNum, totalPrice }
      }
    },
    calcActive(item) {
      var that = this
      return function(item) {
        if (item.levelNo) {
          return item.flowItemStatus
        } else {
          if (item.subFlowCg && item.subFlowCg.length > 0) {
            // return item.flowItemStatus
          }
        }
      }
    }
  },
  watch: {
    orderRow: {
      immediate: true,
      handler(newValue) {
        this.getFlowDetails(newValue)
      }
    }
  },
  methods: {
    getFlowDetails(row, e) {
      this.loading = true
      queryOrderFLowDetail({ orderNo: row.orderNo }).then(response => {
        this.loading = false
        this.productList = response.data.orderGoodsDetails ? response.data.orderGoodsDetails.map((item) => {
          return {
            ...item,
            executeStatus: _.get(_.find(EXECUTE_STATUS, { value: item.executeStatus }), 'text', '')
          }
        }) : []
        this.flowItemDTOS = response.data.flowItemDTOS
      })
    }
  }
}
</script>

<style lang="scss">
.detail-ordermanage {
  background-color: #fff;

  .search-top {
    display: flex;
    justify-content: space-between;

    .leball {
      display: flex;
    }
  }

  .el-table .cell {
    padding: 3px 0;
  }

  .el-input--mini .el-input__inner {
    height: 21px;
  }

  .step .el-input-number__increase {
    height: 21px;
  }

  .step .el-input-number__decrease {
    height: 21px;
  }

  .el-input-number--mini {
    width: 90px;
  }

  .actions {
    padding-left: 13px;
  }

  .super {
    padding: 20px 0;
    width: 100%;

    .super-content {
      width: 100%;
      color: #666666;

      .super-tree {
        font-size: 12px;
        width: 350px;
        height: 101px;
        border: 1px solid #e4e4e4;
        // margin: 5px auto 0;
        margin: 0 auto;
        padding: 10px;
        border-radius: 10px;
        position: relative;
        box-shadow: 0px 2px 12px #00000019;
        &.active {
          // &::before{
          //   content: '';
          //   width: 2px;
          //   height: 103px;
          //   position: absolute;
          //   top: -103px;
          //   left: 50%;
          //   background-color: rgb(64, 158, 255);
          //   margin-left: -1px;
          // }
        }
      }

      .super-line {
        width: 2px;
        height: 101px;
        background-color: #ededed;
        margin: 0 auto;
        position: relative;

        .tubiao {
          width: 10px;
          height: 10px;
          position: absolute;
          bottom: -1px;
          left: -6px;
          color: #c0c4cc;
        }
      }
      .super-fen {
        font-size: 12px;
        width: 550px;
        height: 500px;
        border: 2px solid #ededed;
        margin: 0 auto;
        border-radius: 5px;
        display: flex;
        &.activeb {
          border-color: rgb(64, 158, 255);
        }
        .super-left {
          width: 350px;
          height: 101px;
          border: 1px solid #ededed;
          margin-top: 101px;
          margin-left: -176px;
          background-color: #fff;
          padding: 10px;
          box-shadow: 0px 2px 12px #00000019;
          border-radius: 10px;
        }
        .active {
          background-color: rgb(64, 158, 255);
          color: #fff;
          position: relative;
          &::before {
            content: "";
            width: 2px;
            height: 103px;
            position: absolute;
            top: -103px;
            left: 50%;
            background-color: rgb(64, 158, 255);
            margin-left: -1px;
          }
        }
        .right {
          height: 100%;
          margin-left: 174px;
          display: flex;
          margin-top: 100px;
          .super-right {
            width: 350px;
            height: 101px;
            border: 1px solid #ededed;
            background-color: #fff;
            padding: 10px;
            box-shadow: 0px 2px 12px #00000019;
            border-radius: 10px;
          }
          .active {
            background-color: rgb(64, 158, 255);
            color: #fff;
          }
        }
      }
      .left {
        // .super-left.active:first-child {
        //   &::after {
        //     content: "";
        //     width: 274px;
        //     height: 2px;
        //     position: absolute;
        //     top: -104px;
        //     left: 50%;
        //     background-color: rgb(64, 158, 255);
        //     margin-left: -1px;
        //   }
        // }
      }
      .right {
        .super-right.active:first-child {
          &::after {
            content: "";
            width: 274px;
            height: 2px;
            position: absolute;
            top: -103px;
            right: 50%;
            background-color: rgb(64, 158, 255);
            margin-left: -1px;
          }
        }
      }
      .active {
        background-color: rgb(64, 158, 255);
        color: #fff;
      }
      .node-item:last-child .super-line {
        display: none;
      }
    }
  }
  ::v-deep .y-table {
    margin: 10px 0px !important;
  }
  .total {
    margin-right: 83px;
  }
  .num {
    margin-right: -545px;
  }
  .body-table {
    padding: 0 !important;
  }
  .body-table .table-wrap {
    margin-top: -10px;
  }
}
::v-deep .el-table__cell {
  div {
    padding: 0 5px;
  }
}

.super-branch-box {
  font-size: 12px;
  width: 550px;
  height: 509px;
  margin: 0 auto;
  border-radius: 5px;
  display: flex;
  position: relative;
  border: 1px solid #ededed;
  .super-branch {
    position: absolute;
    top: 0;
    width: 550px;
    height: 130px;
    border: 2px solid #ededed;
    border-bottom: none;

    z-index: 100;
    &.actives {
      border-color: rgb(64, 158, 255);
    }
  }

  .left {
    position: absolute;
    height: 100%;
    width: 275px;
    left: 0;
    z-index: 200;
    .super-branch-left {
      width: 350px;
      height: 101px;
      border: 1px solid #ededed;
      margin-top: 101px;
      margin-left: -176px;
      background-color: #fff;
      padding: 10px;
      box-shadow: 0px 2px 12px #00000019;
      border-radius: 10px;
      z-index: 200;
      &.actives {
        background-color: rgb(64, 158, 255);
        color: #ffffff;
        position: relative;
        &::before {
          content: "";
          width: 2px;
          height: 106px;
          position: absolute;
          top: 99px;
          left: 50%;
          background-color: rgb(64, 158, 255);
          margin-left: -1px;
        }
        &:first-child:before {
          content: "";
          width: 2px;
          height: 103px;
          position: absolute;
          top: 99px;
          left: 50%;
          background-color: rgb(64, 158, 255);
          margin-left: -1px;
        }
        &:last-child:after {
          content: "";
          width: 276px;
          height: 2px;
          position: absolute;
          top: 203px;
          left: 50%;
          background-color: rgb(64, 158, 255);
          margin-left: -1px;
        }
      }
    }
  }

  .right {
    position: absolute;
    right: 0;
    height: 100%;
    width: 275px;
    z-index: 200;
    .super-branch-right {
      margin-top: 101px;
      margin-left: 101px;
      width: 350px;
      height: 101px;
      border: 1px solid #ededed;
      background-color: #fff;
      padding: 10px;
      box-shadow: 0px 2px 12px #00000019;
      border-radius: 10px;
      &.actives {
        background-color: rgb(64, 158, 255);
        color: #ffffff;
        position: relative;
        &::before {
          content: "";
          width: 2px;
          height: 308px;
          position: absolute;
          top: 99px;
          left: 50%;
          background-color: rgb(64, 158, 255);
          margin-left: -1px;
        }
        &:last-child:after {
          content: "";
          width: 276px;
          height: 2px;
          position: absolute;
          top: 405px;
          right: 50%;
          background-color: rgb(64, 158, 255);
          margin-left: -1px;
        }
      }
    }
  }
}
</style>
