
// 引入axios
import axios from 'axios'
// 引入提示框(以实际项目为准)

import { Notification, MessageBox, Message } from 'element-ui'
// 引入路由(以实际项目为准)
import router from '@/router'
//引入loading组件(以实际项目为准)
import { Loading } from 'element-ui'
// 创建的axios的实列 instance

export const baseUrl =  process.env.VUE_APP_BASE_API
// export const baseUrl = 'https://idp.yinsitech.com/api'
const instance = axios.create({
  baseURL: baseUrl + '/distributor-mall', // 基准地址  可直接配置路径,也可代理路径(以实际项目为准)
  timeout: 80000 // 超时时间
})
// 请求拦截
// 声明变量接收logding
let loadingInstance

// 是否显示重新登录
export const isRelogin = { show: false }


function extractAccessTokenValue(cookies) {
  let accessTokenCookie = cookies.find(cookie => cookie.startsWith('accessToken='))
  if (accessTokenCookie) {
    let accessToken = accessTokenCookie.split('=')[1]
    return accessToken
  } else {
    return null
  }
}

instance.interceptors.request.use((config) => {
  const cookies = document.cookie.split('; ')
  let token = extractAccessTokenValue(cookies)
  // 设置请求头
  config.headers.Authorization = token
  //设置logding配置 赋值
  // loadingInstance = Loading.service({
  //   lock: true,
  //   text: '拼命加载中...',
  //   spinner: 'el-icon-loading',
  //   background: 'rgba(0, 0, 0, 0.7)'
  // })
  // 返回请求头
  return config
}, err => {
  // 请求失败返回失败信息
  return Promise.reject(err)
})

// 响应拦截
instance.interceptors.response.use((res) => {
  // 响应成功则关闭loading加载
  // loadingInstance.close()
  //返回响应数据
  if (res?.data?.code == 200) {
    return res.data
  } else if (res.data?.code == 401) { 
    if (!isRelogin.show) {
      isRelogin.show = true
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
        isRelogin.show = false
        // store.dispatch('user/LogOut').then(() => {
          // location.href = '/index'
        // })
        router.push({ path: '/login', query: { type: "mall" } })
      }).catch(() => {
        isRelogin.show = false
      })
    }
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    // return res.data
  } else {
    Message.error(res.data.msg);
    return res.data
  }
}, error => {
  // 响应失败则关闭loading加载
  
  let { message } = error
  // eslint-disable-next-line eqeqeq
  if (message == 'Network Error') {
    message = '网络错误,请检查网络'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  if (document.getElementsByClassName('el-message').length === 0) {
    Message({ message: message, type: 'error', duration: 5 * 1000 })
  }
  return Promise.reject(error)
})
export default instance
