
const formatMoney = function(value, decimalPlaces=2) {
  if (!value) return '--'
  let handleNum = parseFloat(value)
  let isToFixed = handleNum.toString().includes('.') && handleNum.toString().split('.')[1].length > 2
  if (isToFixed) {
    handleNum = handleNum.toFixed(decimalPlaces)
  }
  return String(handleNum).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}



const formatDate = function(value, type='YYYY-MM-DD HH:mm:ss') {
  if (!value) return '--'
  return moment(value).format(type)
}
const install = function(Vue) {
  Vue.filter('formatMoney', formatMoney)
  Vue.filter('formatDate', formatDate)
}
export default install



