<template>
  <div class="detailjump" :style="changeHover(navBackground)">
    <!--面包屑-->
    <div class="detailjump_box">

      <div class="bread">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/website/shophome/shop' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goScreen">
            产品筛选
          </el-breadcrumb-item>
          <el-breadcrumb-item>产品详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="detail_info">
        <!--   大小图切换展示-->
        <div class="detail_img">
          <div class="bigimg" v-if="bigPhotoUrl">
            <img style="width: 100%;height: 100%" :src="bigPhotoUrl" alt="big">
          </div>
          <div class="bigimg" v-if="!detailProducut.img1Url">
            <img :src="noneImages" alt="" style="height: 100%;height: 100%;">
          </div>
          <div class="smalimg_box">
            <div class="smalimg" :class="activeSmalingIndex == 1?'active':''" v-if="detailProducut.img1Url">
              <img @click="showsmall1(1)" style="width: 100%;height: 100%" :src="detailProducut.img1Url" alt="big">
            </div>
            <div class="smalimg"  v-if="!detailProducut.img1Url">
              <img :src="noneImages" alt="" style="height: 100%;height: 100%;">
            </div>
            <div class="smalimg"  :class="activeSmalingIndex == 2?'active':''"  v-if="detailProducut.img2Url" style="margin: 0 5px;">
              <img @click="showsmall1(2)" style="width: 100%;height: 100%" :src="detailProducut.img2Url" alt="big">
            </div>
            <div class="smalimg" v-if="!detailProducut.img2Url" style="margin: 0 5px;">

              <img :src="noneImages" alt="" style="height: 100%;height: 100%;">
            </div>
            <div class="smalimg"  :class="activeSmalingIndex == 3?'active':''"  v-if="detailProducut.img3Url">
              <img @click="showsmall1(3)" style="width: 100%;height: 100%" :src="detailProducut.img3Url" alt="big">
            </div>
            <div class="smalimg" v-if="!detailProducut.img3Url">

              <img :src="noneImages" alt="" style="height: 100%;height: 100%;">
            </div>
          </div>
        </div>
        <!-- 右侧信息展示-->
        <div class="detail_right">
          <!-- 右上       -->
          <div class="right_price">
            <div class="name_price">
              <h2 class="ftsize-20" style="margin: 0 0 10px 0">
                {{detailProducut.brand}}{{detailProducut.productCategory}}{{ detailProducut.series }}</h2>
              <p><span class="w60">促销价：</span>
                <span v-if="loginCheck">{{detailProducut.costPrice}}</span>
                <span v-if="!loginCheck" style="color: #FF0066;"> 登录查看 </span>
              </p>
              <p><span class="w60">市场价：</span><span>￥<del>{{detailProducut.recommendPrice}}个</del></span>
              </p>
            </div>
            <div class="name_logo">
              <img v-if="detailProducut.brandImgUrl" :src="detailProducut.brandImgUrl" alt="logo">
              <img v-else :src="noneImages" alt="">

              <div class="totaljoincar">
                <el-input-number class="gwcNumber" size="mini" v-model="shopTotal" @change="handleChange" :min="0"
                  label="描述文字"></el-input-number>
                <el-button class="gwcButton" type="primary" @click="joinShopCar" round>加购物车<i
                    class="el-icon-shopping-cart-2"></i></el-button>
              </div>
            </div>
          </div>
          <!-- 右中       -->
          <div class="center_info">
            <p><span>品牌：</span><span>{{detailProducut.brand}}</span></p>
            <p><span>订货号：</span><span>{{detailProducut.orderName}}</span></p>
            <p><span>品类：</span><span>{{detailProducut.productCategory}}</span></p>
            <p><span>型号：</span><span>{{detailProducut.marque}}</span></p>
            <p><span>系列：</span><span>{{detailProducut.series}}</span></p>
            <p><span>包装规格：</span><span>{{ detailProducut.minPackageNum}}{{detailProducut.unit}}/箱(盒)</span></p>
            <!--          <p><span>包装规格：</span><span>{{detailProducut.packingSpecification[0]}}个/盒,{{detailProducut.packingSpecification[1]}}盒/箱</span></p>-->
            <div style="width: 100%;padding: 10px 0;display: flex;align-items: center;">
              <span class="w60">规格描述：</span>
              <span
                style="width: calc(100% - 60px);display: inline-block;max-height: 100px;overflow: auto;">{{detailProducut.productDesc}}</span>
            </div>
          </div>
          <!-- 右下       -->
          <div class="center_info">
            <p v-if="detailProducut.deliveryTerm>=0&&detailProducut.productInventorySum>0">
              <span>货期：</span>
              <span
                style="display: inline-block;width: 15%;background: #2DD5A5;text-align: center;color: white;font-size: 12px;">
                现货
              </span>
            </p>
            <p v-else>
              <span>货期：</span>
              <span
                style="background: #FD5366;color: white;display: inline-block;width: 15%;text-align: center;font-size: 12px;">
                {{ detailProducut.deliveryTerm }}天
              </span>
            </p>
            <p><span>库存：</span><span>{{detailProducut.productInventorySum}}个</span></p>
            <div style="width: 100%;"><span class="w60">配送地址:</span><span v-if="detailProducut.address">{{detailProducut.address}}</span></div>
            <div style="width: 100%;padding: 5px 0"><span class="w60">支付方式:</span><span>银行对公转账</span>
            </div>
            <div style="width: 100%;"><span class="w60">质保政策:</span><span v-if="detailProducut.warranty">质保期{{detailProducut.warranty}}个月</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_info">
        <bottomInfo />
      </div>

    </div>

  </div>
</template>

<script>
import bottomInfo from "@/views/job/bottomInfo.vue";
import { conditionProductList, joinCar } from "@/api/productscreening";
import noneImages from '@/assets/images/noneImages.svg'

export default {
  name: "detailjump",
  components: { bottomInfo },
  data() {
    return {
      noneImages: noneImages,
      //  大图地址,默认展示第一张，正式服对接
      bigPhotoUrl: '',
      detailProducut: {
        name: '施耐德按钮指示灯XB2',
        promotionPrice: 9999,
        marketPrice: 2.99,
        logoUrl: require('../../assets/images/p6.png'),
        trademark: '西门子',
        orderName: 'XB2BA31C',
        category: '低压配电',
        modelNo: 'XB2BA31C=AB2BZ01C+ZB2BA3C',
        series: 'XB2',
        packingSpecification: ['10', '15'],
        productDesc: '平头按钮、绿色、22mm、1NO、自复型、金属、不带灯',
        deliveryTime: 1,
        productInventorySum: 300,
        address: '新疆/马尔扎哈',
        payType: 1,
        warranty: 12
      },


      //  背景色
      navBackground: '#0079FE',
      shopTotal: 0,
      loginCheck: false,
      activeSmalingIndex:0,
    }
  },
  created() {
  },
  mounted() {

    let backColor = localStorage.getItem('navBackground')
    this.navBackground = backColor ? JSON.parse(backColor) : '#0079FE'
    const loginCheck = this.$Cookie.get('userName')
    this.loginCheck = !!loginCheck
    this.getProduct()
  },
  methods: {
    changeHover(color) {
      return {
        "--bgColor": color + '4d',
        "--color-hover": color
      }
    },
    getProduct() {
      let routeParam = JSON.parse(this.$route.query.product)
      let queryParams = {
        distributorId: this.$Cookie.get('distributorId'),
        distributorCompanyId: this.$Cookie.get('distributorCompanyId'),
        marque: routeParam.marque
      }
      if (this.$Cookie.get('accessToken')) {
        let user = this.$Cookie.get('user_info')
        const tempUserInfo = JSON.parse(user)
        queryParams.customerId = tempUserInfo.customerId
      }
      // console.log(queryParams)
      conditionProductList(queryParams).then((res) => {
        if (res.code === 200) {
          this.detailProducut = res.data
          if (this.detailProducut.img1Url) {
            this.showsmall1(1)
          }
        }
      })
    },
    showsmall1(param) {
      
      if (param === 1) {
        this.activeSmalingIndex = param
        this.bigPhotoUrl = this.detailProducut.img1Url
      } else if (param === 2) {
        this.activeSmalingIndex = param
        this.bigPhotoUrl = this.detailProducut.img2Url
      } else if (param === 3) {
        this.activeSmalingIndex = param
        this.bigPhotoUrl = this.detailProducut.img3Url
      } else if (param === 4) {
        this.$message.warning('暂无更多图片')
      }

    },
    // 商品数量事件
    handleChange() { },
    /**
     * Date:2024/03/11
     * Author:wt
     * Function:加购物车
     * @param:无
     */
    joinShopCar() {
      if (this.shopTotal <= 0) {
        this.$message.error('请先添加产品数量')
      } else {
        const loginCheck = this.$Cookie.get('userName')
        if (loginCheck) {
          let user = this.$Cookie.get('user_info')
          const tempUserInfo = JSON.parse(user)
          const queryParams = {
            shoppingCartProducts: [{
              marque: this.detailProducut.marque,
              quantity: this.shopTotal
            }],
            userId: tempUserInfo.userId
          }
          joinCar(queryParams).then((res) => {
            if (res.code === 200) {
              this.$message.success('加入购物车成功')
              this.$parent.$parent.jumpShopping()
            }
          })
        } else {
          this.$message.warning('请先登录')
        }
      }
    },
    goScreen() {
      let routeParam = JSON.parse(this.$route.query.product)
      let parObj = JSON.stringify(routeParam)
      this.$router.push({
        path: '/website/shophome/productscreening',
        query: {
          product: parObj
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.detailjump {
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
  .detailjump_box {
    height: 100%;
    width: 100%;
    overflow: auto;
  }
  .bread {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .detail_info {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    .detail_img {
      width: 370px;
      height: 495px;
      .bigimg {
        width: 370px;
        height: 370px;
        img {
          box-sizing: border-box;
          border: 1px solid #e0e0e0;
        }
      }
      .smalimg_box{
        display: flex;
        width: 370px;
        height: 120px;
        margin-top: 5px;
      }
      .smalimg {
        width: 120px;
        height: 120px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        cursor: pointer;
          img {
            box-sizing: border-box;
            border: 1px solid #e0e0e0;
            &:hover {
              border-color: var(--color-hover);
            }
            &:active {
              border-color: var(--color-hover);
            }
            &:focus {
              border-color: var(--color-hover);
            }
          }
         
      }
      .active{
        img {
          border-color: var(--color-hover);
        }
      }
    }
    .detail_right {
      margin-left: 5px;
      height: 495px;
      width: calc(100% - 370px);
      .right_price {
        position: relative;
        width: 100%;
        height: 26%;
        display: flex;
        flex-wrap: nowrap;
        background: white;
        border-bottom: 1px solid #ebeef5;
        .name_price {
          width: 80%;
          height: 100%;
          padding: 5px 15px;
          display: flex;
          margin: 0 0;
          font-size: 12px;
          color: #666666;
          flex-direction: column; /* 垂直方向上按顺序排列 */
          p {
            color: #666666;
            margin: 10px 0;
          }
        }

        .name_logo {
          position: absolute;
          background: white;
          width: 136px;
          height: 100%;
          right: 0;
          top: 0;
          img {
            position: absolute;
            right: 22px;
            top: 0;
            width: 136px;
            height: 80px;
          }
          .totaljoincar {
            position: absolute;
            right: 0;
            bottom: 5px;
            width: 300px;
            height: 50px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            .gwcNumber {
              height: 30px;
              width: 100px;
            }
            .gwcButton {
              margin-left: 20px;
              height: 28px;
              width: 120px;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .center_info {
        font-size: 12px;
        color: #666666;
        width: 100%;
        height: 37%;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 15px;
        border-bottom: 1px solid #ebeef5;
        background: white;
        p {
          width: 50%;
          span:nth-child(1) {
            display: inline-block;
            // width: 22%;
            width: 60px;
          }
          span:nth-child(2) {
            display: inline-block;
            width: calc(100% - 60px);
          }
        }
      }
    }
  }
  .bottom_info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.w60 {
  display: inline-block;
  width: 60px;
}
</style>
