import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
// 文件上传组件
import FileUpload from '@/components/FileUpload'
import plugins from './plugins' // plugins

/**引入api统一入口,注意默认导入了index.js */
// import api from '@/utils/user'


import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/icon-font/iconfont.css'
import '@/assets/styles/index.scss' // global css
import filters from './utils/filters'

import './assets/icons' // icon
Vue.use(filters)
Vue.use(ElementUI)
Vue.use(plugins)
Vue.component('FileUpload', FileUpload)
Vue.prototype.$Cookie = Cookies
Vue.config.productionTip = false

// Vue.prototype.$http = api
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
