<template>
  <div class="after_sales">
    <div class="after_sales_box">
      <div class="serve_box">
        <div class="serve">
          <div class="serve_title">
            <img class="serve_logo" :src="systemLogoUrl? systemLogoUrl :require('../../assets/images/u2135.png')" />
            服务工作单
          </div>
          <div class="sub-title">
            <div class="left">工单编号：</div>
            <div class="right">
              <span class="is-required text">服务时间：</span>
              <el-date-picker v-model="serviceTime" pos type="daterange" value-format="yyyy-MM-dd"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="mini" align="right" />
            </div>
          </div>
          <table class="serve-table">
            <tr>
              <td class="label">
                <p class="is-required text">
                  服务主题：
                </p>
              </td>
              <td colspan="3" class="tc">
                <el-input v-model="queryData.serviceTheme" maxlength="80" size="mini" />
              </td>
              <td class="label">提报时间：</td>
              <td class="disabled"></td>
            </tr>
            <tr>
              <td class="label">
                <p class="is-required text">客户名称：</p>
              </td>
              <td class="disabled">
                {{  queryData.serviceCustomerName }}
                <!-- <el-input v-model="queryData.serviceCustomerName" maxlength="30" size="mini" placeholder="请输入客户名称" /> -->
              </td>
              <td class="label">客户编号：</td>
              <td class="disabled">{{ queryData.customerId }}</td>
              <td class="label">
                <p class="is-required text">服务类型：</p>
              </td>
              <td>

                <el-select size="mini" class="tc" v-model="queryData.serviceType" placeholder="请选择"
                  @change="changeServeType">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td class="label">
                <p class="is-required text">服务地点：</p>
              </td>
              <td class="cityAddress">
                <CityList ref="cityList" :max-len="2" size="mini" format="/" @change="getCity" />
                <!-- <img src="../../assets/images/zb.jpg" alt="">
                <el-cascader size="mini" :options="optionsAddress" v-model="queryData.serviceAddressTemp"
                  @change="addressChange">
                </el-cascader> -->
              </td>
              <td colspan="2">
                <el-input v-model="queryData.serviceAddress" maxlength="80" size="mini">
                  <template #append></template>
                </el-input>
              </td>
              <td class="label">
                <p class="is-required text">涉及品类：</p>
              </td>
              <td>
                <el-input v-model="queryData.category" maxlength="10" size="mini" />
              </td>
            </tr>
            <tr>
              <td class="label">
                <p class="text is-required">联系人：</p>
              </td>
              <td>
                <el-input v-model="queryData.contacts" maxlength="10" size="mini" />
              </td>
              <td class="label">
                <p class="is-required text">联系电话：</p>
              </td>
              <td>
                <el-input v-model="queryData.telephone" maxlength="11" size="mini" />
              </td>
              <td class="label">负责销售：</td>
              <td class="disabled">{{ queryData.saleUserName }}</td>
            </tr>
            <tr>
              <td class="label">
                <p class="is-required text">问题描述：</p>
              </td>
              <td colspan="4">
                <el-input v-model="queryData.requirement" :rows="3" maxlength="300" type="textarea" class="textArea">
                  <template #append></template>
                </el-input>
              </td>
              <td class="checkList">
                <el-checkbox v-model="queryData.warranty" type="checkbox" :true-label="0" :false-label="1" label="保内"
                  class="checkItem" />
                <el-checkbox v-model="queryData.warranty" type="checkbox" :true-label="1" :false-label="0" label="保外"
                  class="checkItem" />
              </td>
            </tr>
            <tr class="label disabled service-content">
              <td rowspan="12" class="label">服务内容：</td>
              <td colspan="2">
                <span class="seq">序号</span>
                <span class="market">描述</span>
              </td>
              <td>
                单价
              </td>
              <td>
                数量
              </td>
              <td>
                总价
              </td>
            </tr>
            <tr v-for="i in 10" :key="i" class="disabled">
              <td colspan="2">
                <span class="seq">{{ i }}</span>
                <span class="market"></span>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="disabled">
              <td colspan="4">总计</td>
              <td></td>
            </tr>
            <tr class="h150 disabled">
              <td class="label">客户确认：</td>
              <td colspan="3" class="tl">
                <p class="label tip">请对本次服务进行评价：</p>
                <div class="checkList" style="margin-bottom:40px">
                  <p v-for="item in ['非常满意','很满意','基本满意','不满意','非常不满意']" :key="item" class="checkItem">
                    <input type="checkbox" :value="item" disabled />
                    <span class="check-label">{{ item }}</span>
                  </p>
                </div>
              </td>
              <td colspan="2" class="tl label">
                <p class="tip" style="margin-top:40px">客户签字：</p>
                <p class="time">
                  <span>年</span>
                  <span>月</span>
                  <span>日</span>
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="footer">
        <el-button style="width: 15%;" type="primary" @click="submit">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, codeToText } from 'element-china-area-data'
import { submitOrder, workOrderNo } from "@/api/aftersales";
import CityList from '@/components/CityList/CityList.vue';
export default {
  name: "aftersales",
  components:{
    CityList
  },
  data() {
    return {
      // 地区组件
      optionsAddress: provinceAndCityData,
      queryData: {
        mallServiceNo: null,
        serTime: [],
        serviceCustomerId: '',
        serviceTheme: '',
        submissionTime: '',
        serviceCustomerName: '',
        customerId:'',
        city:'',
        serviceType: '',
        category: '',
        serviceAddressTemp: [],
        serviceAddress: '',
        contacts: '',
        telephone: '',
        saleUserName: '',
        requirement: '',
        warranty:0
      },
      options: [
        { value: 1, label: '售前交流' },
        { value: 2, label: '样机调试' },
        { value: 3, label: '交付调试' },
        { value: 4, label: '售后服务' },
      ],
      paone: false,
      outlaw: false,
      // 服务内容数据
      tableData: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      greatSatisfaction: false,
      satisfaction: false,
      beBasicallySatisfied: false,
      noSatisfaction: false,
      veryNoSatisfaction: false,
      cookieInfo: {},
      systemLogoUrl: '',
      customerName:'',
      serviceTime:[]
    }
  },
  mounted() {
    this.getSaleName()
    let sysConfig = JSON.parse(this.$Cookie.get('sysConfig'))
    if (sysConfig) {
      this.systemLogoUrl = sysConfig.systemLogoUrl
    }

    let user_info =  JSON.parse(this.$Cookie.get('user_info')) 
    this.queryData.serviceCustomerName = user_info.customerName
    this.queryData.customerId = user_info.customerId
  },
  methods: {
    /**
     * Date:2024/3/21
     * Author:luhua
     * Function:服务类型改变+工单编号获取
     * @param:无
     */
    changeServeType() {
      workOrderNo({ serviceType: this.queryData.serviceType }).then(res => {
        if (res.code === 200) {
          this.queryData.mallServiceNo = res.data
        }
      })
    },
    /**
     * Date:2024/2/28
     * Author:luhua
     * Function:服务时间选择
     * @param:无
     */
    changeTime() {
      // console.log(this.queryData.serTime)
      this.queryData.serviceStartTime = this.queryData.serTime[0]
      this.queryData.serviceEndTime = this.queryData.serTime[1]
      // const user =  this.$Cookie.get('user_info')
      // const userInfo = JSON.parse(user)
      // console.log(userInfo)
    },
    /**
     * Date:2024/2/28
     * Author:luhua
     * Function:提交售后服务
     * @param:无
     */
    submit() {
      let msg = ''
      if (!this.serviceTime.length) {
        msg = '请选择服务时间'
      } else if (!this.queryData.serviceTheme) {
        msg = '请输入服务主题'
      } else if (!this.queryData.serviceAddress || !this.queryData.city) {
        msg = '请输入服务地点'
      } else if (!this.queryData.category) {
        msg = '请输入涉及品类'
      } else if (!this.queryData.requirement) {
        msg = '请输入问题描述'
      }
      if (msg) {
        this.$modal.msgError(msg)
        return
      }
      this.queryData.serviceStartTime = this.serviceTime[0]
      this.queryData.serviceEndTime = this.serviceTime[1]
      // delete this.queryData.serTime;
      // delete this.queryData.serviceAddressTemp;
      this.queryData.submissionTime = this.getCurrentFormattedTime()
      submitOrder(this.queryData).then(res => {
        if (res.code === 200) {
          this.$message.success('提交成功')
          Object.assign(this.queryData,this.$options.data().queryData)
        }
      })
    },
    getCity(res) {
       this.queryData.city = res.pathLabels.join('/')
    },
    addressChange(arr) {
      let addressName = `'${codeToText[arr[0]]}/${codeToText[arr[1]]}'`
      this.queryData.city = addressName
    },
    /**
     * Date:2024/3/21
     * Author:luhua
     * Function:自动生成提报时间
     * @param:无
     */
    getCurrentFormattedTime() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要加 1
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}`;
      return formattedTime;
    },
    /**
     * Date:2024/3/21
     * Author:luhua
     * Function:获取负责销售
     * @param:无
     */
    getSaleName() {
      let user = this.$Cookie.get('user_info')
      const tempUserInfo = JSON.parse(user)
      this.queryData.saleUserName = tempUserInfo.saleUserName ? tempUserInfo.saleUserName : ''
      this.queryData.serviceCustomerId = tempUserInfo.customerId ? tempUserInfo.customerId : ''
      this.queryData.distributorId = this.$Cookie.get('distributorId')
      this.queryData.distributorCompanyId = tempUserInfo.distributorCompanyId
    },
  },
}
</script>

<style  lang="scss" scoped>
.after_sales {
  height: 100%;
  width: 100%;
  padding: 10px 0;
  .after_sales_box {
    background: #ffffff;
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 80px;
    .serve_box {
      height: 100%;
      width: 100%;
      overflow: auto;
    }
    .footer {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }
}

.serve {
  font-size: 12px;
  color: #515151;
  min-width: 950px;
  max-width: 1200px;
  margin: 0 auto;
  .serve_title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 150px;
    position: relative;
  }
  .serve_logo {
    width: 60px;
    position: absolute;
    top: 50%;
    left: 150px;
    margin-top: -30px;
  }
  .sub-title {
    overflow: hidden;
    padding: 0 0 10px 70px;
  }
  .sub-title .left {
    float: left;
    line-height: 30px;
  }
  .sub-title .right {
    float: right;
    line-height: 30px;
  }
  .serve .label {
    color: #7f7f7f;
  }
  .serve-table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    table-layout: fixed;
  }
  .serve-table .is-required,
  .sub-title .is-required {
    position: relative;
    line-height: 30px;
    &::before {
      content: "*";
      color: #ff4949;
      margin-right: 4px;
      position: absolute;
      margin-left: -6px;
      margin-top: 3px;
    }
  }
  .serve-table td {
    border: 1px solid #ccc;
    line-height: 20px;
    padding: 1px;
    position: relative;
    min-height: 30px;
    .el-input,
    .inline-input,
    .el-select {
      width: 100%;
    }
    .el-input__inner,
    .el-textarea__inner {
      border-radius: 0;
    }
    .el-select {
      .el-input__inner {
        text-align: center;
      }
    }
  }
  .serve-table .text {
    margin: 0 auto;
    text-align: right;
    width: 60px;
  }
  .serve-table .tl {
    text-align: left;
  }
  .serve-table .tc {
    text-align: center;
  }
  .serve-table .h150 td {
    height: 150px;
  }
  .serve-table .seq {
    width: 40px;
    text-align: center;
    color: #7f7f7f;
    border-right: 1px solid #eee;
    float: left;
    position: relative;
    margin: -3px 0 -3px 0;
    padding: 3px 0;
  }
  .serve-table .market {
    display: inline-block;
    width: calc(100% - 40px);
  }
  .serve-table .w60 {
    width: 60%;
    text-align: center;
    color: #7f7f7f;
    border-right: 1px solid #eee;
    float: left;
    position: relative;
    margin: -3px 0 -3px -10px;
    padding: 3px 0;
  }
  .serve-table .w40 {
    display: inline-block;
    width: 40%;
  }
  .serve-table .tip {
    padding-left: 15px;
  }
  .serve-table .time {
    padding-left: 50px;
    margin-top: 20px;
  }
  .serve-table .time span {
    padding-left: 40px;
  }
  .checkList .checkItem {
    display: inline-block;
    vertical-align: middle;
    margin: 20px 10px 0;
  }
  .checkList .checkItem .check-label {
    display: inline-block;
    vertical-align: middle;
  }
  input[type="checkbox"] {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  .disabled {
    background: #f8f8f8;
  }

  .cityAddress {
    position: relative;
    img {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 3px;
      margin: auto;
      z-index: 10;
    }
    ::v-deep .el-cascader {
      .el-input {
        .el-input__inner {
          padding-left: 30px;
        }
      }
    }
  }
}

.serve-table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  table-layout: fixed;
}
.serve-table .is-required,
.sub-title .is-required {
  position: relative;
  line-height: 30px;
  &::before {
    content: "*";
    color: #ff4949;
    margin-right: 4px;
    position: absolute;
    margin-left: -6px;
    margin-top: 3px;
  }
}
.serve-table td {
  border: 1px solid #ccc;
  line-height: 20px;
  padding: 1px;
  position: relative;
  min-height: 30px;
  .el-input,
  .inline-input,
  .el-select {
    width: 100%;
  }
  .el-input__inner,
  .el-textarea__inner {
    border-radius: 0;
  }
  .el-select {
    .el-input__inner {
      text-align: center;
    }
  }
}
.serve-table .text {
  margin: 0 auto;
  text-align: right;
  width: 60px;
}
.serve-table .tl {
  text-align: left;
}
.serve-table .tc {
  text-align: center;
}
.serve-table .h150 td {
  height: 150px;
}
.serve-table .seq {
  width: 40px;
  text-align: center;
  color: #7f7f7f;
  border-right: 1px solid #eee;
  float: left;
  position: relative;
  margin: -3px 0 -3px 0;
  padding: 3px 0;
}
.serve-table .market {
  display: inline-block;
  width: calc(100% - 40px);
}
.serve-table .w60 {
  width: 60%;
  text-align: center;
  color: #7f7f7f;
  border-right: 1px solid #eee;
  float: left;
  position: relative;
  margin: -3px 0 -3px -10px;
  padding: 3px 0;
}
.serve-table .w40 {
  display: inline-block;
  width: 40%;
}
.serve-table .tip {
  padding-left: 15px;
}
.serve-table .time {
  padding-left: 50px;
  margin-top: 20px;
}
.serve-table .time span {
  padding-left: 40px;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #0079fe;
}
::v-deep .el-input__inner{
  border-radius: 0;
}
::v-deep .el-textarea__inner{
  border-radius: 0;
}
</style>
