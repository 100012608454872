<template>
  <div class="shop-box">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "shop",
  components: {},
  data() {
    return {}
  },
  watch: {
  },
  created() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const lastPart = url.searchParams.get('code');
    const mallInfoId = url.searchParams.get('mallInfoId');
    if(mallInfoId){
      sessionStorage.setItem('mallInfoId', mallInfoId)
    }
    if (lastPart) {
      this.$Cookie.set('distributor', lastPart)
      sessionStorage.setItem('mallInfoId', 0)
    }
    if (this.$route.path == "/website/shophome") {
      this.$router.push({path:"/website/shophome/shop",query:this.$route?.query})
    }
  },
  methods: {

  },
  computed: {},
}
</script>
<style  lang="scss" scoped>
.shop-box {
  width: 100%;
  height: 100%;
}
</style>