<template>
  <div class="body-table">
    <div class="top-tool">
      <el-form ref="form" label-width="55px" label-position="left" class="ftsize-12">
        <el-row type="flex" justify="space-between">
          <el-col :span="8">
            <el-form-item label="订单类型:">
              <el-col :span="15">
                <div class="ftsize-12" style="color: #666666;">客户订单_{{ searchKey }}</div>
              </el-col>
              <!-- <el-col :span="15" style="margin-left: 5px">
                <div>{{ searchKey }}</div> -->
              <!-- <el-autocomplete v-model="searchKey" class="inline-input" :fetch-suggestions="querySearch" placeholder="请输入内容" suffix-icon="el-icon-search" @select="handleSelect" /> -->
              <!-- </el-col> -->
            </el-form-item>
          </el-col>
          <el-col :span="8" :push="3">
            <el-form-item label="财务规则:">
              <span class="ftsize-12" style="color: #666666;"> {{ parlist?.financialRules}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8" :push="3">
            <el-form-item label="发货模式:">
              <el-switch class="ftsize-12" v-model="form.executionMode" active-color="#13ce66"
                inactive-color="#ff4949" /><span class="ftsize-12"
                style="padding-left: 10px">{{ form.executionMode ? '整单自发' : '手动排发' }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="8">
            <el-form-item label="签约方式:">
              <span class="ftsize-12" style="color: #666666;"> {{ parlist?.signingMethod}} </span>
            </el-form-item>
          </el-col>
          <el-col :span="8" :push="3">
            <el-form-item label="需货时间:">
              <el-date-picker v-model="form.deliveryDate" type="date" placeholder="选择日期" size="small"
                style="height: 30px;" @change="changeDeliveryDate" :picker-options="disabledDate" />
            </el-form-item>
          </el-col>
          <el-col :span="8">

          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-wrap">
      <div class="left">
        <div class="left_textarea">
          <el-input v-model="searchName" class="textarea" type="textarea" placeholder="例如：TM251MESE 10" />
          <div class="upload_btn">
            <el-upload action accept=".xlsx, .xls" :auto-upload="false" :show-file-list="false" :on-change="handle">
              <el-button type="primary" size="small">Excel添加</el-button>
            </el-upload>
            <!-- <el-button size="small" type="primary">导入Excell</el-button> -->
            <el-button size="small" type="primary" @click="exportToExcel">下载模板</el-button>
          </div>
        </div>
        <div class="search-btn">
          <div class="btn" @click="search">
            <img src="@/assets/images/search2.svg" alt="">
            <div class="text" style="text-align: center">添加</div>
          </div>
        </div>
      </div>
      <div class="right">
        <filter-table :height="'calc(100% - 25px)'" :rowHeight="'30px'" :loading="loading" :data="dataList"
          :columns="columnList" :bottom="true">
          <template #deliveryTerm="scope">
            <span class="sText"
              :class="scope.row.deliveryTerm === '现货' ? 'green' : ''">{{ scope.row.deliveryTerm }}</span>
          </template>
          <template #deliveryTermDifference="scope">
            <span class="sText"
              :class="scope.row.deliveryTermDifference === '现货' || scope.row.deliveryTermDifference >= 0 ? 'green' : 'red'">{{ scope.row.deliveryTermDifference }}</span>
          </template>
          <template #benchmarkPrice="scope">
            {{scope.row.benchmarkPrice | formatMoney}}
          </template>
          <template #productAmount="scope">
            {{scope.row.productAmount | formatMoney}}
          </template>
          <template #productNum="scope">
            <el-input-number v-model="dataList[scope.row.$index].productNum" :min="0" :max="99999" class="step"
              size="mini" @change="handleChange(scope.row,$event)" />
          </template>
          <template #result="scope">
            <span v-if="scope.row.result">✅</span>
            <span v-else>❌</span>
          </template>
          <template #inventoryDifference="scope">
            <span class="sNum"
              :class="scope.row.openInventoryNum <= 0 ? 'red': scope.row.inventoryDifference >= 0 ? 'green' :'orange'">{{ scope.row.inventoryDifference }}</span>
          </template>
          <template #action="scope">
            <span class="actions">
              <el-button type="danger" @click.stop="handleDelete(scope.row)">
                <img src="@/assets/images/del.svg" />
              </el-button>
            </span>
          </template>
          <template #bottom>
            <div class="bottom">
              <span class="total-name">总计</span>
              <div class="numAndtotal">
                <div class="num">{{ total().totalNum | formatMoney}}</div>
                <div class="total">{{ total().totalPrice | formatMoney}}</div>
              </div>
            </div>

          </template>
        </filter-table>
      </div>
    </div>
    <div class="tip">批量输入型号和数量，用空格隔开，也可从Excel中复制粘贴</div>
    <div class="footer" style="margin-top: 20px">
      <el-button type="primary" @click="submitOrder" style="border:none">提交订单</el-button>
      <el-button @click="cleanData">清空</el-button>
    </div>

    <el-dialog title="货号/型号确认" :visible.sync="dialogVisible" width="60%" :close-on-click-modal="false">
      <div style="margin:20px 0;">以下货号/型号错误或者存在多个产品，请确认！</div>
      <filter-table :data="dialogDataList" :columns="dialogColumnList" :bottom="true" :height="600" border
        :arraySpanMethod="arraySpanMethod" :cellStyle="cellStyle">
        <template #result="scope">
          <span v-if="scope.row.result">✅</span>
          <span v-else>❌</span>
        </template>
        <template #info="scope">
          <span style="color:#ff6d87;">{{ scope.row.info }}</span>
        </template>
      </filter-table>
      <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;align-items: center;">
        <el-button size="small" @click="dialogVisible = false" style="color:#000000;">取 消</el-button>
        <el-button size="small" type="primary" @click="dialogVisible = false"
          style="background: #0079fe;color: #FFFFFF;">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as XLSX from 'xlsx/xlsx.mjs'
import { saveAs } from 'file-saver';

import { getUserInfo } from '@/utils/auth'
import { commitOrder, getCustomerInfo, queryProductList, updateOrder, generateOuotation, queryOrderProductDetails } from '@/api/orderManages'
import { shoppingCar, shoppingCarDelete } from "@/api/productscreening"
import FilterTable from '@/components/FilterTable/index.vue'
import { EXECUTE_STATUS, ORDER_STATUS } from '@/utils/const'
import * as _ from 'lodash'

import { downLoadFile } from '@/utils/index'
const columnList = [
  {
    label: '产品型号',
    align: 'left',
    prop: 'productMarque',
    width: '120px',
    type: 'select'
  },
  {
    label: '品牌',
    prop: 'brand'
  },
  {
    label: '品类',
    prop: 'productCategory'
  },
  {
    label: '参考货期',
    slotName: 'deliveryTerm'
  },
  {
    label: '货期差额',
    slotName: 'deliveryTermDifference'
  },
  {
    label: '单价',
    slotName: 'benchmarkPrice',
    align: "right"
  },
  {
    label: '数量',
    slotName: 'productNum',
    width: '120px',
    align: "center"
  },
  {
    label: '库存差额',
    slotName: 'inventoryDifference',
    align: "center",
    width: '100px'
  },
  {
    label: '总价',
    slotName: 'productAmount',
    width: '120px',
    align: "right"
  },
  {
    label: '操作',
    slotName: 'action',
    width: '120px'
  }
]
const dialogColumnList = [
  {
    label: '货号/型号',
    prop: 'productMarque',
    width: '200px'
  },
  {
    label: '数量',
    prop: 'productNum',
    width: "80px"
  },
  {
    label: '查询结果',
    slotName: 'result',
    width: "80px"
  },
  {
    label: '错误提示',
    slotName: 'info',
  },
]
export default {
  name: "quickorder",
  components: {
    FilterTable
  },
  data() {
    return {
      disabledDate: {
        disabledDate: time => time.getTime() < new Date(new Date().setHours(0, 0, 0, 0))
      },
      loading: false,
      orderTypes: [
        {
          label: '客户订单',
          value: 1
        },
        {
          label: '项目订单',
          value: 2
        },
        {
          label: '次品订单',
          value: 3
        }
      ],
      filterData: [],
      searchKey: '',
      selectedItem: null,
      dataList: [],
      parlist: {
        financialRules: '',
        signingMethod: ''
      },
      columnList,
      searchName: '',
      searchData: {},
      restaurants: [],
      form: {
        deliveryDate: new Date(),
        orderType: 4
      },
      commonParams: {
        distributorCompanyId: getUserInfo().distributorCompanyId || 0,
        distributorId: getUserInfo().distributorId
      },
      dialogVisible: false,
      diaLogloading: false,
      dialogColumnList,
      dialogDataList: [],
      ISgouWuche: false,
      gwcfirst: true,
      gwcList: [],
    }
  },
  computed: {
    total() {
      return () => {
        let totalNum = 0
        let totalPrice = 0
        this.dataList.map(item => {
          if (item.productNum) {
            totalNum = totalNum + item.productNum
          }
          item.inventoryDifference = item.openInventoryNum - item.productNum
          item.productAmount = item.productNum * item.benchmarkPrice
          totalPrice = totalPrice + item.productAmount
        })
        return { totalNum, totalPrice }

      }
    }
  },
  mounted() {
    this.filterDataByOrderType()
    if (Object.keys(this.$route.query).length !== 0) {
      this.ISgouWuche = true
      const serchdata = this.$route.query.product
      const serchdatas = JSON.parse(serchdata)
      const formattedString = serchdatas.map(item => `${item.marque} ${item.quantity}`).join('\n')
      // console.log(formattedString, 'mmm');
      this.searchName = formattedString
      if (this.searchName) {
        this.search()
        this.ISgouWuche = true
      }
    } else {
      this.ISgouWuche = false
    }
    // this.init()

  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // if (!row.isExist) {
      //   if (columnIndex === 2) {
      //     return [1, 4];
      //   } else if (columnIndex > 2) {
      //     return [0, 0];
      //   }
      // }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 4) {
        return { color: 'red', width: '100%', padding: '0' }
      }
    },
    init() {

      this.form = row
      let user = this.$Cookie.get('user_info')
      this.tempUserInfo = JSON.parse(user)
      this.selectedItem = this.tempUserInfo
      this.commonParams =
      {
        distributorCompanyId: this.tempUserInfo.distributorCompanyId || 0,
        distributorId: this.tempUserInfo.distributorId
      }
      this.form.orderType = 4
      this.searchKey = row.customerName
      this.loadProductDetail(row)
    },
    loadProductDetail(row) {
      this.loading = true
      queryOrderProductDetails({ ...this.commonParams, orderNo: row.orderNo }).then(response => {
        this.loading = false
        this.dataList = response.data.map(d => {
          return {
            ...d,
            executeStatus: _.get(_.find(EXECUTE_STATUS, { value: d.executeStatus }), 'text', ''),
            deliveryTermDifference: d.deliveryTerm === 0 ? '现货' : Math.ceil((new Date(row.deliveryDate) - new Date()) / (1000 * 3600 * 24)) - d.deliveryTerm,
            deliveryTerm: d.deliveryTerm === 0 ? '现货' : d.deliveryTerm,
            inventoryDifference: d.openInventoryNum - d.productNum
          }
        })
      })
    },
    changeOrderType(item) {
      this.searchKey = this.tempUserInfo.customerName
      this.selectedItem = null
      this.form.financialRules = ''
      this.form.signingMethod = ''
      this.filterDataByOrderType()
    },
    handleSelect(item) {
      this.selectedItem = this.tempUserInfo
      this.form = {
        ...this.form,
        financialRules: this.selectedItem.financialRules,
        signingMethod: this.selectedItem.signingMethod
      }
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    filterDataByOrderType() {

      let user = this.$Cookie.get('user_info')
      this.tempUserInfo = JSON.parse(user)
      this.commonParams =
      {
        distributorCompanyId: this.tempUserInfo.distributorCompanyId || 0,
        distributorId: this.tempUserInfo.distributorId
      }
      this.selectedItem = this.tempUserInfo
      this.searchKey = this.tempUserInfo.customerName
      const orderType = 4
      const data = this.tempUserInfo.customerId
      getCustomerInfo(data).then(res => {
        this.parlist = res.data
      })
      // const params = {
      //   distributorCompanyId:  this.commonParams.distributorCompanyId || 0,
      //   distributorId:  this.commonParams.distributorId,
      //   // distributorCompanyId: 200,
      //   // distributorId: 1,
      //   orderType: orderType,
      //   name: this.searchKey
      // }
      // this.loading = true
      // filterByOrderType(params).then(response => {
      //   if(response.data){
      //     this.restaurants = response.data.map(item => {
      //     item.value = item.customerName
      //     return item
      //   })
      //   }

      //   this.loading = false
      // })
    },
    search(type, list) {
      if (!this.selectedItem) {
        this.$message.warning({
          title: '警告',
          message: '请先选择客户'
        })
        return
      }
      if (type === 'excell') {
        this.searchProductData(list)
      } else {
        if (!this.searchName) {
          this.$message.error({
            title: '警告',
            message: '请输入产品型号及数量'
          })
          return
        }
        const arr = []
        const arrtext = this.searchName.split('\n') // 以转行符切割文本字符串
        arrtext.forEach((item, index) => {
          if (item) {
            const regex = /\t|\s/
            const cols = item.split(regex)
            if (cols[0]) {
              arr.push({ productMarque: cols[0], productNum: cols[1] })
            }
          }
        })
        this.searchProductData(arr)
      }


    },
    searchProductData(list) {

      this.loading = true
      const params = {
        ...this.commonParams,
        productTradeList: list,
      }
      if (this.form.orderType === 4) {
        params.orderType = 4
        params.customerID = this.selectedItem.customerId
        // params.customerID =10085759
      }
      // this.dataList = []
      queryProductList(params).then(response => {
        if (response.data) {
          let queryList = JSON.parse(JSON.stringify(response.data.errorInfoList))
          let resData = JSON.parse(JSON.stringify(response.data.orderProductResultList))
          // queryList.map((item, index) => {
          //   item.index = index
          //   item.isExist = false
          //   item.brand = "未查询到该货号/型号对应的产品信息"
          // })
          // resData.map(item => item.isExist = true)
          // Object.assign(queryList, resData)
          this.dialogDataList = JSON.parse(JSON.stringify(queryList))
          if (queryList.length > 0) {
            this.dialogVisible = true
          }

          // this.dataList =
          //   resData.map(item => {
          //     return {
          //       ...item,
          //       deliveryTermDifference: item.deliveryTerm === 0 ? '现货' : item.deliveryTermDifference,
          //       deliveryTerm: item.deliveryTerm === 0 ? '现货' : item.deliveryTerm
          //     }
          //   }) || []
          const deliveryDate = new Date()
          resData.map(item => {
            item.deliveryTermDifference = (item.openInventoryNum - item.productNum) >= 0 ? '现货' : Math.ceil((new Date(deliveryDate) - new Date()) / (1000 * 3600 * 24)) - item.deliveryTerm
            item.copydeliveryTerm = JSON.parse(JSON.stringify(item.deliveryTerm))
            item.deliveryTerm = item.openInventoryNum > 0 && (item.openInventoryNum - item.productNum) >= 0 ? '现货' : item.deliveryTerm
            item.inventoryDifference = (item.openInventoryNum - item.productNum) >= 0 ? '0' : item.openInventoryNum - item.productNum
            const idx = this.dataList.findIndex(a => a.productMarque === item.productMarque)
            if (idx === -1) {
              this.dataList.push(item)
            } else {
              this.dataList[idx].productNum += item.productNum
            }
          })
          this.loading = false
          if (this.ISgouWuche && this.gwcfirst) {
            this.gwcList = false
            const userId = JSON.parse(this.$Cookie.get('user_info'))
            let str = ""
            queryList.map(d => {
              if (d.result == false) str += d.productMarque + ','
            })

            let data = {
              marque: str,
              userId: userId.userId
            }
            if (str.length > 0) {
              shoppingCarDelete(data).then(res => {
                this.$parent.jumpShopping()
              })
            }
          }
        } else {
          this.loading = false
          this.$message.warning({
            title: '警告',
            message: '未查询到产品信息'
          })

        }

      })

    },
    // remoteMethod(query) {
    //   if (query !== '') {
    //     this.loading = true
    //     setTimeout(() => {
    //       this.loading = false
    //       this.options = this.list.filter(item => {
    //         return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
    //       })
    //     }, 200)
    //   } else {
    //     this.options = []
    //   }
    // }
    createFilter(queryString) {
      return restaurant => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      if (this.ISgouWuche) {
        let user = this.$Cookie.get('user_info')
        const tempUserInfo = JSON.parse(user)
        let data = {
          marque: row.productMarque,
          userId: tempUserInfo.userId
        }
        shoppingCarDelete(data).then(res => {
          this.dataList.splice(row.$index, 1)
          this.$modal.msgSuccess("删除成功")
          this.$parent.jumpShopping()
        })
      } else {
        this.dataList.splice(row.$index, 1)
        this.$modal.msgSuccess("删除成功")
      }
      // const roleIds = row.roleId || this.ids
      // this.$modal.confirm('是否确认删除角色编号为"' + roleIds + '"的数据项？').then(function() {
      //   return delRole(roleIds)
      // }).then(() => {
      //   this.getList()
      //   this.$modal.msgSuccess('删除成功')
      // }).catch(() => {})
    },
    submitOrder() {
      if (_.isEqual(_.size(this.dataList), 0)) {
        this.$message.warning({
          title: '警告',
          message: '请先添加产品'
        })
        return
      }
      this.loading = true
      const params = {
        ...this.form,
        ...this.parlist,
        ...this.commonParams,
        executionMode: _.isEqual(this.form.executionMode, true, false) ? 2 : 1,
        orderGoodsNum: this.total().totalNum,
        orderAmount: this.total().totalPrice,
        orderGoodsDetails: this.dataList.map((d) => {
          return {
            ...d,
            executeStatus: _.get(_.find(EXECUTE_STATUS, { text: d.executeStatus }), 'value', 0)
          }
        })
      }
      params.orderStatus = _.get(_.find(ORDER_STATUS, { value: params.orderStatus }), 'text', '')
      params.value = this.searchKey
      // console.log(params);
      // params.financialRules = this.parlist.financialRules
      // params.signingMethod = this.parlist.signingMethod
      // delete params.createTime
      commitOrder(params).then(res => {
        this.loading = false
        if (res.code == 200) {

          // this.$router.push('/website/ordermanage');
        }
        if (res.data) {
          this.$confirm('是否下载合同', '', {
            confirmButtonText: '下载',
            cancelButtonText: '继续',
            type: 'warning'
          }).then(() => {
            this.$modal.msgSuccess('订单新增成功')
            this.cleanData()
            downLoadFile(res.data)
          }).catch(() => {
            this.$modal.msgSuccess('订单新增成功')
            this.cleanData()
          })
        } else {
          this.$modal.msgSuccess('订单新增成功')
          this.cleanData()
        }

      })
    },
    generateQuotation() {
      if (_.isEqual(_.size(this.dataList), 0)) {
        this.$message.warning({
          title: '警告',
          message: '请先添加产品'
        })
        return
      }
      // console.log(this.parlist,'form');
      if (!this.parlist.financialRules && !this.parlist.signingMethod) {
        this.$message.error({
          title: '警告',
          message: '请配置财务规则及合同模版'
        })
        return
      } else if (!this.parlist.financialRules) {
        this.$message.error({
          title: '警告',
          message: '请配置财务规则'
        })
        return
      } else if (!this.parlist.signingMethod) {
        this.$message.error({
          title: '警告',
          message: '请配置合同模版'
        })
        return
      }
      this.loading = true
      const params = {
        ...this.form,
        ...this.parlist,
        ...this.commonParams,
        executionMode: _.isEqual(this.form.executionMode, true, false) ? 2 : 1,
        orderGoodsNum: this.total().totalNum,
        orderAmount: this.total().totalPrice,
        orderGoodsDetails: this.dataList.map((d) => {
          return {
            ...d,
            executeStatus: _.get(_.find(EXECUTE_STATUS, { text: d.executeStatus }), 'value', 0)
          }
        })
      }
      params.value = this.searchKey
      // params.financialRules = this.parlist.financialRules
      // params.signingMethod = this.parlist.signingMethod
      // delete params.createTime
      generateOuotation(params).then(res => {
        this.loading = false
        this.$modal.msgSuccess('生成报价成功')
      })
    },
    handleChange(row, value) {
      this.changeDeliveryDate(this.form.deliveryDate)
    },
    changeDeliveryDate(e) {
      if (e) {
        this.dataList.map(item => {
          item.deliveryTerm = item.openInventoryNum > 0 && (item.openInventoryNum - item.productNum) >= 0 ? '现货' : item.copydeliveryTerm
          if (item.openInventoryNum - item.productNum >= 0) {
            item.deliveryTermDifference = '现货'
          } else {
            item.deliveryTermDifference = Math.ceil((new Date(e) - new Date()) / (1000 * 3600 * 24)) - item.copydeliveryTerm
          }
        })
      } else {
        this.dataList.map(item => {
          item.deliveryTerm = item.openInventoryNum > 0 && (item.openInventoryNum - item.productNum) >= 0 ? '现货' : item.copydeliveryTerm
          if (item.openInventoryNum - item.productNum >= 0) {
            item.deliveryTermDifference = '现货'
          } else {
            item.deliveryTermDifference = '-'
          }
        })
      }
    },
    cleanData() {
      if (this.ISgouWuche) {
        let user = this.$Cookie.get('user_info')
        const tempUserInfo = JSON.parse(user)
        if (this.$parent.$data.gwcList.length > 0) {
          let str = ""
          this.$parent.$data.gwcList.map(d => {
            str += d.marque + ','
          })
          let data = {
            marque: str,
            userId: tempUserInfo.userId
          }
          shoppingCarDelete(data).then(res => {
            this.dataList = []
            this.searchName = ''
            this.$parent.jumpShopping()
          })
        } else {
          this.dataList = []
          this.searchName = ''
        }

      } else {
        this.dataList = []
        this.searchName = ''
      }
    },

    //  读取excell文件
    readFile(file) {//文件读取
      return new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsBinaryString(file);//以二进制的方式读取
        reader.onload = ev => {
          resolve(ev.target.result);
        }
      })
    },
    async handle(ev) {
      let file = ev.raw;
      if (!file) {
        this.$message.warning("文件打开失败")
        return;
      } else {
        let data = await this.readFile(file);
        let workbook = XLSX.read(data, { type: "binary" });//解析二进制格式数据
        //  console.log('二进制数据的解析:')
        //  console.log(workbook)
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];//获取第一个Sheet
        let result = XLSX.utils.sheet_to_json(worksheet);//json数据格式
        // console.log('最终解析的 json 格式数据:')
        // console.log(result)
        const arr = []
        result.map(item => {
          if (item['型号']) {
            const obj = {
              productMarque: item['型号'],
              productNum: item['数量'] ? item['数量'] : ''
            }
            arr.push(obj)
          }
          // this.searchName += item['型号'] ? ` \n${item['型号']} ${item['数量'] ? item['数量'] : ''} ` : ''
        })
        this.search('excell', arr)
      }
    },
    // 下载Excell文件模板
    exportToExcel() {
      // 创建工作簿
      const wb = XLSX.utils.book_new();

      // 创建工作表数据
      const ws_data = [
        ['型号', '数量'],
        ['', '']
      ];

      // 将数据转换为工作表
      const ws = XLSX.utils.aoa_to_sheet(ws_data);

      // 将工作表添加到工作簿
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // 生成Excel文件
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // 字符串转ArrayBuffer
      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }

      // 创建Blob对象
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

      // 使用file-saver保存文件
      saveAs(blob, '产品导入模板.xlsx');
    }
  }
}
</script>
<style lang="scss" scoped>
.el-row {
  height: 35px;
}
::v-deep .y-table {
  margin: 0;
}
.search-box {
  .form-box {
    display: flex;
    justify-content: space-between;
  }
}
.el-input-number__increase {
  height: 21px;
}
.el-input-number__decrease {
  height: 21px;
}
.el-input-number--mini {
  width: 90px;
}
.numAndtotal {
  display: flex;
}
.total {
  width: 110px !important;
  margin-right: 130px;
  text-align: right !important;
}
.num {
  width: 110px !important;
  margin-right: 100px;
}

.step {
  line-height: 20px;
  ::v-deep .el-input__inner {
    padding: 0;
    height: 20px;
    line-height: 20px;
  }
}
::v-deep .el-form {
  .el-form-item__label {
    font-size: 12px;
    padding: 0;
  }
}
::v-deep .el-date-editor {
  .el-input__inner {
    height: 30px;
  }
}
</style>
