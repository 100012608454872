<template>
  <div style="height: 100%;">
    <!-- Table列表 -->
    <div class="y-table">
      <el-table
        ref="listTable"
        v-loading="loading"
        :border="border"
        :row-class-name="tableRowClassName"
        :data="filteredTableData"
        tooltip-effect="dark"
        :header-cell-style="{
          height: '25px',
          padding: '0',
        }"
        :highlight-current-row="highlightCurrentRow"
        :height="countHeight"
        :row-key="rowKey"
        :cell-style="{ width: '100%', padding: '0' }"
        :row-style="{ height: rowHeight }"
        lazy
        :load="load"
        :span-method="arraySpanMethod"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        @selection-change="$emit('selection-change', $event)"
        @row-click="handleRowClick"
        @expand-change="handleExpandChange"
      >
        <el-table-column
          v-if="selectable"
          type="selection"
          :selectable="selectInit"
          align="center"
          width="40"
        />
        <el-table-column
          v-if="serial"
          label="序号"
          type="index"
          width="50"
          align="center"
          :fixed="serialFixed"
        />
        <el-table-column
          v-for="(column, index) in columns"
          :key="index + '-' + column.name"
          show-overflow-tooltip
          :prop="column.prop"
          :label="column.label"
          :align="column.align || 'center'"
          :width="column.width || 0"
          :min-width="column.minWidth"
          :sortable="!!column.sortable"
          :fixed="column.fixed"
        >
          <!-- Header -->
          <template slot="header">
            <span class="header-default" :class="{'header-active':column.filterable&&filterItem(column.prop)}">
              {{ column.label }}
              <i
                v-if="column.filterable&&!filterItem(column.prop)"
                class="el-icon-arrow-down icon-right"
                @click.stop="(e) => onFilterClick(e, column)"
              ></i>
              <i
                v-if="column.filterable&&filterItem(column.prop)"
                class="el-icon-close"
                @click="onFilterDelete(column)"
              ></i>
            </span>
          </template>
          <template slot-scope="scope">
            <template v-if="column.filter">
              <slot :name="column.prop" :row="{...scope.row, $index:scope.$index}">
                <!-- 价格过滤 -->
                <template v-if="column.filter === 'formatMoney'">
                  {{ scope.row[column.prop] | formatMoney }}
                </template>
                <!-- 时间过滤 -->
                <template v-else-if="column.filter === 'formatTime'">
                  {{ scope.row[column.prop] | formatDate('YYYY/MM/DD') }}
                </template>
                <template v-else>
                  {{ scope.row[column.prop] }}
                </template>
              </slot>
            </template>
            <template v-else-if="column.slotName">
              <slot :name="column.slotName" :row="{...scope.row, $index:scope.$index}"></slot>
            </template>
            <template v-else-if="column.link">
              <template v-if="scope.row[column.prop]=='-'">
                {{ scope.row[column.prop] }}
              </template>
              <el-link
                v-else
                :underline="false"
                type="primary"
                @click="column.click(scope.row)"
              >{{ scope.row[column.prop] }}</el-link>
            </template>
            <template v-else>
              {{ scope.row[column.prop] }}
            </template>
          </template>
        </el-table-column>
        <slot></slot>
      </el-table>
      <div v-if="bottom" class="bottom">
        <div v-if="bottomBtns" class="btns">
          <div class="sbtn" @click="reverseSelect()">反选</div>
          <div class="sbtn" @click="toggleSelection()">取消选择</div>
        </div>
        <slot name="bottom"></slot>
      </div>
      <slot name="bottomLine"></slot>
    </div>
    <!-- 分页信息 -->
    <div v-if="paging" class="page-con">
      <el-pagination
        background
        :current-page="page"
        :page-sizes="pageSizeArr"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="$emit('size-change', $event)"
        @current-change="$emit('current-change', $event)"
      />
    </div>
    <!-- 筛选弹出框 -->
    <filter-popover ref="popoverDom">
      <div v-if="currentColumn&&currentColumn.type!='slot'" class="table-pop-filter-con">
        <div class="body">
          <!-- 筛选项 -->
          <filter-content
            :data="initFilterValue"
            :type="currentColumn.type"
            :options="options"
            :default-options="defaultOptions"
            @valueChange="onValueChange"
          />
        </div>
        <div class="footer">
          <el-button type="primary" size="mini" @click="confirmFilter">筛选</el-button>
        </div>
      </div>
      <div v-else class="table-pop-filter-con">
        <div class="filter-box"><slot name="search"></slot></div>
      </div>
    </filter-popover>
  </div>
</template>
<script>
import FilterPopover from './popover.vue'
import FilterContent from './filterContent.vue'

export default {
  name: 'FilterTable',
  components: {
    FilterContent,
    FilterPopover
  },
  props: {
    /** 是否可以复选 */
    selectable: {
      type: Boolean,
      default: false
    },
    rowKey: {
      type: String,
      default: 'id'
    },
    rowHeight: {
      type: String,
      default: '25px'
    },
    height: {
      type: [Number, String],
      default: `calc(100vh - ${150}px)`
    },
    /** 是否展示序号 */
    serial: {
      type: Boolean,
      default: true
    },
    serialFixed: {
      type: Boolean,
      default: false
    },
    /** 加载数据状态 */
    loading: {
      type: Boolean,
      default: false
    },
    /** 边框 */
    border: {
      type: Boolean,
      default: false
    },
    /** table 数据 */
    data: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    /** table 列 */
    columns: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    /** 底部信息 */
    bottom: {
      type: Boolean,
      default: false
    },
    /** 底部按钮信息 */
    bottomBtns: {
      type: Boolean,
      default: false
    },
    /** 分页信息 */
    paging: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: undefined
    },
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageSizeArr: {
      type: Array,
      default: () => {
        return [10, 20, 30, 40]
      }
    },
    load: {
      type: Function,
      default: () => {}
    },
    arraySpanMethod: {
      type: Function,
      default: () => {}
    },
    highlightCurrentRow: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnsList: this.columns,
      currentColumn: null,
      changeValue: null,
      initFilterValue: null,
      filterData: {},
      defaultOptions: null,
      checkedColumns: []
    }
  },
  computed: {
    filteredTableData() {
      // 使用filter方法过滤表格数据
      const filterData = this.data.map((row) => {
        let hidden = false
        for (const key in this.filterData) {
          if (Object.hasOwnProperty.call(this.filterData, key)) {
            const value = this.filterData[key]
            if (value && value.length && !value.includes(row[key])) {
              hidden = true
            }
          }
        }
        row.hidden = hidden
        return row
      })
      return filterData
    },
    countHeight() {
      let height = ''
      if (this.height === 0) {
        height = null
      } else if (typeof (this.height) === 'number') {
        height = `calc(100vh - ${(this.height) + 'px'})`
      } else {
        height = this.height
      }
      return height
    },
    options() {
      if (this.currentColumn) {
        return this.currentColumn.options?.map((item) => item.label)
      } else {
        return []
      }
    },
    isSelect() {
      return ['select'].includes(this.currentColumn.type)
    },
    isDateOrTime() {
      return ['date', 'datetime'].includes(this.currentColumn.type)
    },
    filterItem() {
      return function(prop) {
        const keys = Object.keys(this.filterData)
        return keys.includes(prop)
      }
    }
  },
  mounted() {
    this.checkedColumns = this.columns
      .filter((item) => item.required || !item.hide)
      .map((item) => item.label)
  },
  methods: {
    // 筛选项
    filterOptions(value) {
      const obj = []
      // 找到对应的数据 并添加到obj
      this.data.filter(item => {
        obj.push({
          label: item[value],
          value: item[value]
        })
      })
      // 因为column有重复数据，所以要进行去重
      return this.deWeight(obj)
    },
    // 数组对象去重
    deWeight(arr) {
      for (var i = 0; i < arr.length - 1; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].label === arr[j].label) {
            arr.splice(j, 1)
            j--
          }
        }
      }
      return arr
    },
    onFilterDelete(item) {
      const filterData = { ...this.filterData }
      delete filterData[item.prop]
      this.filterData = filterData
      this.$emit('confirmFilter', filterData)
    },
    onFilterClick(e, column) {
      if (!column.options) {
        column.options = this.filterOptions(column.prop)
      }
      this.currentColumn = column
      this.initFilterValue = this.getInitFilterValue()
      this.$refs.popoverDom.popBy(e.target)
    },
    onValueChange(data) {
      // 临时数据
      this.changeValue = data
    },
    // 获取弹出框显示默认值
    getInitFilterValue() {
      const value = this.filterData[this.currentColumn.prop]
      if (this.isSelect) {
        return this.currentColumn.options
          ?.filter((option) => value?.includes(option.value))
          .map((item) => item.label)
      } else {
        return value
      }
    },
    // 获取emit 真实值
    getEmitValue() {
      if (this.isSelect) {
        return this.currentColumn.options
          ?.filter((option) => this.changeValue.includes(option.label))
          .map((item) => item.value)
      } else {
        return this.changeValue
      }
    },
    // 筛选确定
    confirmFilter() {
      const emitValue = this.getEmitValue()
      this.$set(this.filterData, this.currentColumn.prop, emitValue) // 如果是数组实际对应的是[value,value]
      this.initFilterValue = emitValue
      this.$emit('confirmFilter', this.filterData)
      this.$refs.popoverDom.close()
    },
    // 展开或者关闭
    handleExpandChange(row, expandedRows) {
      if (expandedRows.length > 0) {
        this.$emit('rowClick', row)
      }
    },
    // 展开或者关闭
    handleRowClick(row) {
      if (this.$listeners['line-click']) {
        this.$emit('line-click', row)
        return
      }
      if (this.expanded) {
        row.exexpand = !row?.exexpand
        const copyRow = JSON.parse(JSON.stringify(row))
        this.filteredTableData.forEach(a => {
          if (a?.exexpand) {
            a.exexpand = false
            this.$refs.listTable.toggleRowExpansion(a, false)
          }
        })
        row.exexpand = copyRow.exexpand
        this.$refs.listTable.toggleRowExpansion(row, row.exexpand)
        // this.$emit('rowClick', row)
      } else {
        this.$refs.listTable.toggleRowExpansion(row)
        // this.$emit('rowClick', row)
      }
    },
    reverseSelect() {
      this.filteredTableData.forEach(row => {
        this.$refs.listTable.toggleRowSelection(row)
      })
    },
    toggleSelection() {
      this.$refs.listTable.clearSelection()
    },
    // 把每一行的索引放进row
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
      if (row && row.disabledSelect) {
        return 'disbaled-select-row'
      }
      if (row && row.hidden) {
        return 'hidden-row'
      }
      if (row.beyonday < 4) {
        return 'red-row';
      } else if (row.beyonday < 8) {
        return 'orange-row';
      } else if (row.beyonday < 30) {
        return 'yellow-row';
      }
      return ''
    },
    forTableData(arr, isExpand) {
      arr.forEach(i => {
        this.$refs.listTable.toggleRowExpansion(i, isExpand)
        if (i.children) {
          this.forTableData(i.children, isExpand)
        }
      })
    },
    // 收起
    packUp() {
      this.forTableData(this.filteredTableData, false)
    },
    getTableRef() {
      return this.$refs.listTable
    },
    selectInit(row) {
      return !(row && row.disabledSelect)
    },
    singleTable(row) {
      const key = row.key || ''
      if (!key) return
      const list = this.filteredTableData || []
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        if (item[key] === row.value) {
          this.$refs.listTable.setCurrentRow(item)
          break
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer{
  margin: 10px auto 0;
}
.column-filter {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  & > .option-con {
    flex: 1;
    text-align: right;
  }
}
.filter-info {
  background-color: #dbe1f6;
  padding: 10px;
  color: #435ebe;
  font-size: 14px;
  display: flex;
  margin-bottom: 10px;
  border-radius: 8px;
  & > .title {
    flex: 0 0 auto;
    font-weight: bold;
    padding-top: 5px;
  }
  & > .filters {
    flex: 1;
    padding: 0 10px;
    & > .item {
      font-weight: normal;
      margin: 5px;
      display: inline-block;
      line-height: 20px;

      & > i {
        display: inline-block;
        cursor: pointer;
      }
    }
  }
  & > .options {
    flex: 0 0 auto;
    padding-top: 5px;
  }
}
.table-con {
  & .img-filter {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url("~@/assets/images/user.png") no-repeat center center / 15px auto;
    vertical-align: middle;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    margin-left: 13px;
  }
  & ::v-deep .sort-caret {
    left: 4px;
  }
  & ::v-deep .is-sortable {
    & .img-filter {
      height: 34px;
    }
  }
}
.page-con {
  text-align: right;
  margin-top: 10px;
}
.el-checkbox-group {
  max-height: 600px;
  overflow: auto;
}
.header-default{
  position: relative;
}

.header-active{
  border: 1px solid #0079FE;
  display: block;
  border-radius: 5px 5px 0 0;
  text-align: center;
}
::v-deep .header-active + .caret-wrapper{
  display: none;
}
.icon-right{
  position: absolute;
  transform: translateY(50%);
}

::v-deep .el-table .disbaled-select-row{
  background: #fcfcfc !important;
}
::v-deep .el-table .disbaled-select-row:hover > td.el-table__cell{
  background: #fcfcfc !important;
}
::v-deep .el-table .hidden-row {
	display: none !important;
}
.filter-box{
  width: 150px;
}
</style>
