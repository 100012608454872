<template>
  <div>
    <div class="y-table two">
      <div v-if="top" class="table-top-title">
        <slot name="top"></slot>
      </div>
      <el-table
        ref="expandTable"
        v-loading="loading"
        :border="border"
        :data="data"
        max-height="275"
        tooltip-effect="dark"
        :header-cell-style="{
          height: '25px',
          background: '#f2f2f2',
          padding: '0',
        }"
        :cell-style="{ width: '100%', padding: '0' }"
        :row-style="{ height: rowHeight }"
        :row-class-name="disableSelectRowClass"
        lazy
        @selection-change="$emit('selection-change', $event)"
      >
        <el-table-column
          v-if="selectable"
          type="selection"
          :selectable="selectInit"
          align="center"
          width="50"
        />
        <el-table-column
          v-if="serial"
          label="序号"
          type="index"
          width="46"
          align="center"
        />
        <el-table-column
          v-for="(column, index) in columns"
          :key="index + '-' + column.name"
          show-overflow-tooltip
          :prop="column.prop"
          :label="column.label"
          :align="column.align || 'center'"
          :width="column.width || 0"
          :sortable="!!column.sortable"
        >
          <template slot-scope="scope">
            <template v-if="column.filter">
              <slot :name="column.prop" :row="{...scope.row, $index:scope.$index}">
                <!-- 价格过滤 -->
                <template v-if="column.filter === 'formatMoney'">
                  {{ scope.row[column.prop] | formatMoney }}
                </template>
                <!-- 时间过滤 -->
                <template v-else-if="column.filter === 'formatTime'">
                  {{ scope.row[column.prop] | formatDate('YYYY/MM/DD') }}
                </template>
                <template v-else>
                  {{ scope.row[column.prop] }}
                </template>
              </slot>
            </template>
            <template v-else-if="column.slotName">
              <slot :name="column.slotName" :row="{ ...scope.row, $index: scope.$index }"></slot>
            </template>
            <template v-else-if="column.prop === 'downRate'">
              {{ scope.row[column.prop] }}
            </template>
            <template v-else>
              {{ scope.row[column.prop] }}
            </template>
          </template>
        </el-table-column>
        <slot></slot>
      </el-table>
      <div v-if="bottom" class="bottom">
        <slot name="bottom"></slot>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    rowHeight: {
      type: String,
      default: '25px'
    },
    /** 是否展示序号 */
    serial: {
      type: Boolean,
      default: true
    },
    /** 加载数据状态 */
    loading: {
      type: Boolean,
      default: false
    },
    /** 边框 */
    border: {
      type: Boolean,
      default: false
    },
    /** table 数据 */
    data: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    /** table 列 */
    columns: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    /** 底部信息 */
    bottom: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    /** 是否可以复选 */
    selectable: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
  },
  methods: {
    // getPriceList() {
    //   getPriceConfig().then((res) => {
    //     const data = res.data || {}
    //     for (const n in data) {
    //       if (this.colors[n]) {
    //         this.colors[n].num = data[n] || 0
    //       }
    //     }
    //   }).catch()
    // },
    toggleSelection() {
      this.$refs.listTable.clearSelection()
    },
    selectInit(row) {
      return !(row && row.disabledSelect)
    },
    disableSelectRowClass({ row, rowIndex }) {
      if (row && row.disabledSelect) {
        return 'disbaled-select-row'
      }
      return ''
    }
  }
}
</script>

<style scoped lang="scss">
.y-table.two {
  margin: -1px 1px 1px;
  position: relative;
  border-radius: 0 0 5px 5px;
}
::v-deep .el-table .disbaled-select-row{
  background: #fcfcfc !important;
}
::v-deep .el-table .disbaled-select-row:hover > td.el-table__cell{
  background: #fcfcfc !important;
}
</style>
