import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import Noticedetail from '@/views/shop/noticedetail.vue'
import Website from "@/views/job/website.vue"
import Aftersales from "@/views/job/aftersales.vue"
import Ordermanage from "@/views/job/ordermanage.vue"
import Modifyordermanage from "@/views/quickorder/modifyordermanage.vue"
import Detailordermanage from "@/views/job/detailordermanage.vue"
import Contract from "@/views/contract/contract.vue"
import Modifycontract from "@/views/contract/modifycontract.vue"
import Detailjump from "@/views/screen/detailjump.vue"
import Chat from "@/views/communicate/chat.vue"
import Quickorder from "@/views/quickorder/index.vue"
import Finance from "@/views/finance/index.vue"
import QuotationRecordv from "@/views/job/quotationRecord.vue"

import Web from "@/views/web/web.vue" // 网站
import WebErr from "@/views/web/error.vue"

//  首页 
import Shop from "@/views/shop/shop.vue"
import Shophome from "@/views/shop/shophome.vue"
import Productscreening from '@/views/shop/productscreening.vue'
import brand from "@/views/shop/brand.vue"
import Error from "@/views/error.vue"


Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: 'website/shophome'
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  // {    有意向将 网站项目  丢到商城里面  (因为登录的事) 暂时还是用的网站单独项目
  //   path: '/web',
  //   name: 'web',
  //   component: Web
  // },
  // {
  //   path: '/WebErr',
  //   name: 'WebErr',
  //   component: WebErr
  // },
  {
    component: Website,
    name: 'website',
    path: '/website',
    children: [
      {
        path: 'QuotationRecordv',
        name: 'QuotationRecordv',
        component: QuotationRecordv,
      },
      {
        path: 'aftersales',
        name: 'aftersales',
        component: Aftersales,
      },
      {
        path: 'ordermanage',
        name: 'ordermanage',
        component: Ordermanage,
        children: [
          {
            path: 'modifyordermanage',
            name: 'modifyordermanage',
            component: Modifyordermanage,
          },
          {
            path: 'detailordermanage',
            name: 'detailordermanage',
            component: Detailordermanage,
          },
        ]
      },
      {
        path: 'contract',
        name: 'contract',
        component: Contract,
        children: [
          {
            path: 'modifyordermanage',
            name: 'modifyordermanage',
            component: Modifyordermanage,
          },
          {
            path: 'detailordermanage',
            name: 'detailordermanage',
            component: Detailordermanage,
          },
        ]
      },
      {
        path: 'finance',
        name: 'finance',
        component: Finance,
      },
      {
        path: 'noticedetail',
        name: 'noticedetail',
        component: Noticedetail,
      },
     
      {
        component: Shop,
        path: 'shophome',
        name: 'shophome',
        children: [
          {
            path: 'shop',
            name: 'shop',
            component: Shophome,
          },
          {
            path: 'productscreening',
            name: 'productscreening',
            component: Productscreening,
          },
          {
            path: 'brand',
            name: 'brand',
            component: brand,
          },
          {
            path: 'detailjump',
            name: 'detailjump',
            component: Detailjump,
          },
        ]
      },
      {
        path: 'chat',
        name: 'chat',
        component: Chat,
      },
      {
        path: 'quickorder',
        name: 'quickorder',
        component: Quickorder,
      },
    ],

  },

]

const originaPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originaPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
