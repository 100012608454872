<template>
  <div class="noticeDetail">
    <div v-if="productScreenShow">
      <!--面包屑-->
      <div class="bread">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/website/shophome/shop' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>公告详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="noticedetail">
        <div class="detail">
          <div class="detail_title">{{ form.noticeTitle }}</div>
          <div class="detail_tit">{{ form.subNoticeTitle }}</div>
          <div class="detail_kehu">尊敬的客户：</div>
          <div class="detail_content">
            <pre class="pre">{{ form.noticeContent }} </pre>
          </div>
          <div class="zhang">
            <p class="company">{{form.companyName}}</p>
            <p class="time">{{ form.createTime.split(' ')[0] }}</p>
            <!-- <p class="time">{{ parseTime(new Date(),'{y}年{m}月{d}日') }}</p> -->
          </div>
        </div>
      </div>
      <!--  表格 -->
      <div>
      </div>
    </div>
    <!--  三级路由出口-->
    <router-view v-else />
  </div>
</template>

<script>
import bottomInfo from "@/views/job/bottomInfo.vue";
import { getNoticedeatil } from "@/api/mallindex";

export default {
  name: "noticedetail",
  components: { bottomInfo },
  data() {
    return {
      productScreenShow: true,
      form: {}
    }
  },
  created() {
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      let routeParam = JSON.parse(this.$route.query.product)
      getNoticedeatil(routeParam.noticeId).then(res => {
        this.form = res.data
      })
    },
  }
}
</script>

<style scoped lang="scss">
.noticeDetail {
  width: 100%;
  padding-bottom: 20px;
  .bread {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .noticedetail {
    width: 900px;
    height: fit-content;
    background-color: #fff;
    margin: 10px auto;
    padding: 40px 80px;
    .detail {
      font-family: fangsong;
      position: relative;
      line-height: 35px;
      min-height: 820px;
      padding-bottom: 200px;
      .detail_title {
        font-size: 22px;
        font-family: fangsong;
        font-weight: bold;
        text-align: center;
        color: #333333;
      }
      .detail_tit {
        font-size: 16px;
        color: #333333;
        text-align: center;
        font-family: fangsong;
      }
      .detail_kehu {
        font-family: fangsong;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .detail_content {
        font-family: fangsong;
        font-size: 16px;
        color: #333333;
        text-align: left;
        .pre {
          padding: 0;
          text-indent: 0;
          word-wrap: break-word;
          white-space: pre-wrap;
          max-width: 100%;
        }
      }
    }
    .zhang {
      font-family: fangsong;
      position: absolute;
      right: 0;
      bottom: 20px;
      text-align: center;
      white-space: nowrap;
      font-size: 16px;
      font-weight: bold;
      margin-right: 50px;
      .company {
        font-weight: bold;
        font-family: fangsong;
        font-size: 16px;
        color: #333;
      }
      .time {
        font-weight: bold;
        font-family: fangsong;
        font-size: 16px;
        color: #333;
      }
    }
  }
}
</style>
