<template>
  <div class="body-con" v-if="orderShow">
    <filter-table ref="filterTable" :loading="loading" :height="`calc(100vh - ${230}px)`" :data="dataList"
      :columns="columnList" row-key="orderNo" row-height="50px" @rowClick="handleRowClick">
      <template #orderAmount="scope">
        {{scope.row.orderAmount | formatMoney}}
      </template>
      <template #uploadContract="scope">
        <!-- <el-button v-if="scope.row.orderContractStatus !==3 " class="m-btn" type="primary" @click.stop="uplpadContractEvt(scope.row)"> -->
        <div v-if="scope.row.orderContractStatus != 1 && scope.row.contractType !==4 && scope.row.orderType !== '样机订单'"
          @click.stop>
          <FileUpload btn-label="上传合同" :is-show-tip="false" :file-type="['pdf','doc']"
            @change="(value)=> uplpadContractEvt(value, scope.row)" />
        </div>
        <!-- </el-button> -->
        <!-- <el-button v-else class="m-btn" type="primary" @click.stop="uplpadContractEvt(scope.row)">确认合同</el-button> -->
      </template>
      <template #dtime="scope">
        <div>{{scope.row.deliveryDate.replace('T', ' ') }}</div>
      </template>
      <template #action="scope">
        <el-button-group class="actions">
          <el-button
            type="primary"
            title="查看合同"
            :disabled="scope.row.contractType ==4"
            @click.stop="handleViewContarct(scope.row)"
          >
            <svg-icon icon-class="eye-link" />
          </el-button>
          <el-button
            type="primary"
            title="下载合同"
            :disabled="scope.row.contractType ==4"
            @click.stop="handleDownloadContarct(scope.row)"
          >
            <svg-icon icon-class="download" />
          </el-button>
          <!-- <el-button :disabled="scope.row.contractType ==4" type="primary" title="查看合同"
            @click.stop="handleViewContarct(scope.row)">
            <img src="@/assets/images/eye.svg" />
          </el-button>
          <el-button :disabled="scope.row.contractType ==4" type="primary" title="下载合同"
            @click.stop="handleDownloadContarct(scope.row)">
            <img src="@/assets/images/xiazai.svg" />
          </el-button> -->

          <!-- <el-button type="primary" title="编辑" @click.stop="handleEdit(scope.row)">
            <img src="@/assets/images/bianji.svg" />
          </el-button>
          <el-button type="primary" title="订单详情" @click.stop="handleApplyInvoice(scope.row)">
            <img src="@/assets/images/xiangq.svg" />
          </el-button>
          <el-button type="danger" title="取消订单" @click.stop="handleDelete(scope.row)">
            <img src="@/assets/images/del.svg" />
          </el-button> -->
        </el-button-group>
      </template>
      <el-table-column type="expand" width="1">
        <template scope="scope">
          <expand-table :data="scope.row.expandList" :columns="columnList2" :loading="scope.row.loading" :bottom="true">
            <template #deliveryTerm="mscope">
              <span class="sText"
                :class="scope.row.expandList[mscope.row.$index].deliveryTerm === '现货' ? 'green' : ''">{{ scope.row.expandList[mscope.row.$index].deliveryTerm }}</span>
            </template>
            <template #benchmarkPrice="mscope">
              {{mscope.row.benchmarkPrice | formatMoney}}
            </template>
            <template #productAmount="mscope">
              {{mscope.row.productAmount | formatMoney}}
            </template>
            <template #deliveryTermDifference="mscope">
              <span class="sText"
                :class="scope.row.expandList[mscope.row.$index].deliveryTermDifference === '现货' || scope.row.expandList[mscope.row.$index].deliveryTermDifference >= 0 ? 'green' : 'red'">{{ scope.row.expandList[mscope.row.$index].deliveryTermDifference }}</span>
            </template>
            <template #inventoryDifference="mscope">
              <span class="sNum"
                :class="scope.row.expandList[mscope.row.$index].inventoryDifference > 0 ? 'green' : 'red'">{{ scope.row.expandList[mscope.row.$index].inventoryDifference }}</span>
            </template>
            <template #bottom>
              <div class="left">
                <span class="total-name">总计</span>
              </div>
              <div class="right">
                <span class="num">{{ total(scope.row.expandList).totalNum | formatMoney}}</span>
                <span class="total"
                  style="margin-right: 130px !important;text-align: right;">{{ total(scope.row.expandList).totalPrice | formatMoney}}</span>
              </div>
            </template>
          </expand-table>
        </template>
      </el-table-column>
    </filter-table>
  </div>

  <router-view v-else></router-view>
</template>
<script>
import FilterTable from '@/components/FilterTable/index.vue'
import ExpandTable from '@/components/ExpandTable/index.vue'
import { queryOrderProductDetails, cancelOrder } from '@/api/orderManages'
import {
  getList, uploadContract
} from '@/api/contract'

import { ORDER_STATUS, EXECUTE_STATUS, ORDER_TYPES } from '@/utils/const'
import * as _ from 'lodash'
import { downLoadFile } from '@/utils/index.js'
const columnList = [
  // {
  //   label: '序号',
  //   prop: 'productMarque',
  //   type: 'select',
  //   filterable: true
  // },
  {
    label: '订单号',
    prop: 'orderNo',
    type: 'select',
    align: 'left',
    width: '150px',
    filterable: true
  },
  {
    label: '订单类型',
    prop: 'orderType',
    type: 'select',
    filterable: true
  },
  {
    label: '客户名称',
    prop: 'customerName',
    type: 'select',
    filterable: true,
    align: 'left',
    width: '200px',
  },
  {
    label: '提交人',
    prop: 'submitterName',
    type: 'select',
    filterable: true
  },
  {
    label: '负责销售',
    prop: 'saleUserName',
    type: 'select',
    filterable: true
  },
  {
    label: '发货模式',
    prop: 'executionMode'
  },
  {
    label: '下单时间',
    prop: 'deliveryDate',
    width: '130px',
    filterable: true,
    slotName: 'dtime'
  },
  {
    label: '订单金额',
    prop: 'orderAmount',
    filter: 'formatMoney',
    sortable: true,
    align: "right"
  },
  {
    label: '上传合同',
    slotName: 'uploadContract'
  },

  {
    label: '操作',
    slotName: 'action',
    width: "120px"
  },
  {
    type: 'expend',
    width: '1px'
  }
]
const columnList2 = [
  {
    label: '产品型号',
    prop: 'productMarque',
    align: 'left'
  },
  {
    label: '品牌',
    prop: 'brand'
  },
  {
    label: '品类',
    prop: 'productCategory'
  },
  {
    label: '参考货期',
    slotName: 'deliveryTerm'
  },
  {
    label: '货期差额',
    slotName: 'deliveryTermDifference'
  },
  {
    label: '单价',
    slotName: 'benchmarkPrice',
    align: "right"
  },
  {
    label: '数量',
    prop: 'productNum',
    width: '120px',
    align: "center"
  },
  {
    label: '开放库存',
    prop: 'openInventoryNum',
    align: "center"
  },
  {
    label: '库存差额',
    slotName: 'inventoryDifference',
    align: "center"
  },
  {
    label: '总价',
    slotName: 'productAmount',
    width: '120px',
    align: "right"
  },
  {
    label: '执行进度',
    prop: 'executeStatus',
    width: '120px',
  }
]
export default {
  name: "contract",
  components: {
    FilterTable,
    ExpandTable
  },
  data() {
    return {
      orderShow: true,
      loading: false,
      dataList: [],
      commonParams: {},
      columnList: columnList,
      columnList2: columnList2,
      lockProductItems: [],
      dialogVisible: false,
      selectRow: {}
    }
  },
  computed: {
    total() {
      return function (list) {
        let totalNum = 0
        let totalPrice = 0
        list.map(item => {
          if (item.productNum) {
            totalNum = totalNum + item.productNum
          }
          if (item.productAmount) {
            totalPrice = totalPrice + item.productAmount
          }
        })
        return { totalNum, totalPrice }
      }
    }
  },
  watch: {
    '$route'(to, from) {
      console.log(to)
      if (to.name === 'contract') {
        this.orderShow = true
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      // this.loading = true
      let user = this.$Cookie.get('user_info')
      // let distributorId = this.$Cookie.get('distributorId')
      // let user = this.$Cookie.get('user_info')
      if (user) {
        this.tempUserInfo = JSON.parse(user)
        this.commonParams = {
          distributorId: this.tempUserInfo.distributorId,
          distributorCompanyId: this.tempUserInfo.distributorCompanyId,
        }
        const data = {
          distributorId: this.tempUserInfo.distributorId,
          distributorCompanyId: this.tempUserInfo.distributorCompanyId,
          customerId: this.tempUserInfo.customerId
        }
        getList(data).then(r => {
          if (r.data) {
            this.dataList = r.data.map(item => {
              item.loading = true
              item.expandList = []
              item.orderType = _.get(_.find(ORDER_TYPES, { value: item.orderType }), 'text', '')
              item.executionMode = item.executionMode === 1 ? '手动排发' : '整单自发'
              return item
            })
          }

        })
      }
    },
    handleRowClick(row, e) {
      if (!row.expandList.length) {
        this.dataList[row.index].loading = true
        queryOrderProductDetails({ ...this.commonParams, orderNo: row.orderNo, orderType: _.get(_.find(ORDER_TYPES, { value: row.orderType }), 'text', '') }).then(response => {
          this.dataList[row.index].loading = false
          this.dataList[row.index].expandList = response.data.map(d => {
            return {
              ...d,
              executeStatus: _.get(_.find(EXECUTE_STATUS, { value: d.executeStatus }), 'text', ''),
              deliveryTermDifference: d.deliveryTerm === 0 ? '现货' : Math.ceil((new Date(row.deliveryDate) - new Date()) / (1000 * 3600 * 24)) - d.deliveryTerm,
              deliveryTerm: d.deliveryTerm === 0 ? '现货' : d.deliveryTerm,
              inventoryDifference: d.openInventoryNum - d.productNum
            }
          })
        })
      }
    },
    handleViewContarct(row) {
      if (row.oldFilePath) {
        window.open(row.oldFilePath, ' _blank')
      } else {
        this.$modal.msgWarning("当前还没有上传合同")
      }
    },
    uplpadContractEvt(res, row) {
      uploadContract({ ...this.commonParams, contractId: row.orderContract, newFilePath: res.data.resourceUrl, orderId: row.orderId }).then(() => {
        this.$modal.msgSuccess('上传成功')
        this.getList()
      }).catch(() => { })
    },
    handleDownloadContarct(row) {
      if (row.oldFilePath) {
        downLoadFile(row.oldFilePath, row.orderNo)
      } else {
        this.$modal.msgWarning("当前还没有上传合同")
      }
    },

    handleEdit(row) {
      // this.$parent.handleNewTab('修改订单', row)
      console.log(row);
      // this.orderShow = false
      // let parObj = JSON.stringify(row)
      // this.$router.push({
      //   path: "/website/contract/modifyordermanage",
      //   query: {
      //     product: parObj
      //   }
      // })

    },
    handleApplyInvoice(row) {
      this.orderShow = false
      let parObj = JSON.stringify(row)
      this.$router.push({
        path: "/website/contract/detailordermanage",
        query: {
          product: parObj
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$modal.confirm('是否确认取消订单号' + row.orderNo).then(() => {
        cancelOrder({
          ...this.commonParams,
          orderId: row.orderId
        })
          .then(() => {
            this.getList()
            this.$modal.msgSuccess('取消成功')
          })
          .catch(() => { })
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.body-con {
  padding: 30px 0 0 0;
}
::v-deep .y-table {
  margin: 0 0;
}
.total {
  margin-right: 258px !important;
}
.num {
  margin-right: 222px !important;
}
// ::v-deep .y-table .el-table .el-table__expanded-cell::before {
//   display: none !important;
// }

::v-deep .y-table .bottom .total-name {
  padding-left: 20px;
}
</style>
