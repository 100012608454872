import Cookies from 'js-cookie'
import Store from '@/store'

const TokenKey = 'Admin-Token'

const ExpiresInKey = 'Admin-Expires-In'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getExpiresIn () {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn (time) {
  return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn () {
  return Cookies.remove(ExpiresInKey)
}

export function getUserInfo () {
  let obj = {}
  obj = Store?.state?.userInfo ?? {}
  obj.distributorCompanyId = Cookies.get('distributorCompanyId') || 0
  return obj
}
